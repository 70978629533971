import React, { useEffect } from "react";
import {
  Box,
  Container,
  Heading,
  useDisclosure,
  SimpleGrid,
  Collapse,
  Text,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalFooter,
  CircularProgress,
} from "@chakra-ui/react";

import { Button } from "@chakra-ui/react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { colors } from "../../../theme/styles/colors";
import BreadcrumbBox from "../../../theme/components/breadcrumb";
import TextInput from "../../../theme/components/input/text-input";
import Pagination from "../../../theme/components/pagination/pagination";
import { GET_BREADCRUMB_ROUTES } from "../../../core/utils/navbar-items";
import {
  MENU_NAMES,
  PAGE_SIZE,
  ROLES,
} from "../../../core/utils/constants";
import { GRID_COLUMNS } from "../../../core/utils/grid-columns";
import SelectInput from "../../../theme/components/input/select-input";
import { GoFilter } from "react-icons/go";
import { IoMdAddCircleOutline } from "react-icons/io";
import * as yup from "yup";
import api from "../../../core/api";
import {
  getFilterCount,
  createFilterPayload,
  getAll,
  handleFilterChange,
  handleResetState,
  deleteById,
  CREATE_RESPONSE,
  getAllowedRoles,
} from "../../../core/utils/common-function";
import { Formik, Form } from "formik";
import SCHEMA_VALIDATOR from "../../../core/utils/schema-validator";
import SwitchInput from "../../../theme/components/input/switch-input";
import {
  SuccessAlert,
  WarningAlert,
} from "../../../core/utils/common-sweet-alert";
import { useSelector } from "react-redux";
import DeleteModal from "../../../theme/components/modals/delete-modal";
import Grid from "../../../theme/components/grid/grid";

const ViewPermission = (props) => {
  const selector = useSelector((_) => _);
  const [current, setCurrent] = useState(3);
  const [userId, setUserId] = useState(selector.auth.login.userId);
  const [deleteId, setDeleteId] = useState(null);
  const [role, setRole] = useState();
  const [user, setUser] = useState();
  const [tableRowData, setTableRowData] = useState([]);
  const [currentPage, setCurrentPage] = useState(selector.paginationData.pagination.pageIndex);
  const [currentPageSize, setCurrentPageSize] = useState(selector.paginationData.pagination.pageSize);
  const [activeFilter, setActiveFilter] = useState(0);
  const navigate = useNavigate();
  const params = useLocation();
  const [branchId, setBranchId] = useState(params.state.id);
  const [totalCount, setTotalCount] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [permissionUsers, setPermissionUsers] = useState([]);
  const [permissionIndex, setPermissionIndex] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [show, setShow] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);
  const hasExport =
    selector.auth.login.RoleId == ROLES.SYSTEM_ADMINISTRATOR || 
selector.auth.login.RoleId == ROLES.ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.BRANCH_HEAD;
  const [columns, setColumns] = useState(
    GRID_COLUMNS.VIEW_PERMISSIONS.filter((x) => x.Active && x.Visibility)
  );
  const [apiPayload, setAPIPayload] = useState({
    filter: [],
    pageSize: currentPageSize,
    pageIndex: currentPage,
    branchId: params.state.id,
  });
  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
    branchId: params.state.id,
  });
  const [filterState, setFilterState] = useState({
    role: "",
    isActive: null,
    user: null,
    userName: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [district, setDistrict] = useState([]);
  const [state, setState] = useState([]);
  const [permissionId, setPermissionId] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [viewPermissionId, setViewPermssionId] = useState(null);
  const [initialState, setInitialState] = useState({
    roleId: null,
    userId: null,
    userName: "",
    password: "",
  });

  const validationSchema = yup.object({
    userId: SCHEMA_VALIDATOR.GUID_VALIDATOR,
    roleId: SCHEMA_VALIDATOR.POSITIVE_INTEGER,
    userName: SCHEMA_VALIDATOR.STRING_VALIDATOR,
    password: SCHEMA_VALIDATOR.PASSWORD_VALIDATOR,
  });
  const onModelClose = () => {
    setPermissionId(null);
    setIsEdit(false);
    setIsView(false);
    onClose();
    setInitialState({
      roleId: null,
      userId: null,
      userName: "",
      password: "",
      isActive: false,
    });
  };

  const handleToggle = () => {
    setShow(!show);
  };

  const onChange = (page) => {
    setCurrentPage(page);
    setAPIPayload({
      ...apiPayload,
      pageIndex: page,
    });
  };

  const onDelete = async () => {
    await deleteById("Permission", permissionId);
    getAllRecords();
    setIsDeleteOpen(false);
    onModelClose();
  };

  const getAllPromises = () => {
    const role = getAllowedRoles(payload);
    const user = getAll("User", payload);

    Promise.all([role, user])
      .then((res) => {
        const [role, user] = res;
        const roles = role.data.data.map((role) => ({
          text: role.name,
          value: role.roleId,
        }));
        const users = user.data.data.map((user) => ({
          text: user.name,
          value: user.userId,
        }));
        setRole(roles);
        setUser(users);
      })
      .catch((err) => {});
  };

  const getAllRecords = () => {
    api
      .post("/Branch/getAllPermissions", apiPayload)
      .then((res) => {
        setTotalCount(res.data.data.total);
        setDataSource(res.data.data.data);
        setData(
          CREATE_RESPONSE(GRID_COLUMNS.VIEW_PERMISSIONS, res.data.data.data)
        );
      })
      .catch((err) => {});
  };

  const resetFilterState = () => {
    setCurrentPageSize(PAGE_SIZE);
    setCurrentPage(1);
    setAPIPayload({
      ...apiPayload,
      filter: [],
      pageIndex: 1,
      pageSize: PAGE_SIZE,
    });
    handleResetState(filterState, setFilterState);
    setActiveFilter(0);
    // getAllRecords();
  };

  const getRecord = async () => {
    try {
      if (permissionId) {
        let permission = await api.get(`Branch/get-permission/${permissionId}`);
        let record = permission.data.data;
        setInitialState({
          roleId: record.roleId,
          userName: record.username,
          user: record.userId,
          password: record.password,
          isActive: record.isActive,
          branchId: record.branchId,
          ...record,
        });
        onOpen();
      }
    } catch (error) {}
  };

  useEffect(() => {
    getRecord();
  }, [isEdit, isView]);

  useEffect(() => {
    setData(CREATE_RESPONSE(columns, dataSource));
  }, [columns]);

  useEffect(() => {
    document.title = "View Permission | Digital Filing";
    if (
      apiPayload.branchId != "" &&
      apiPayload.branchId == selector.branch.userBranchId
    ) {
      getAllRecords();
      getAllPromises();
    }
    else if (apiPayload.branchId != selector.branch.userBranchId) {
      setCurrentPage(1);
      setCurrentPageSize(PAGE_SIZE);
      setAPIPayload({
        ...apiPayload,
        pageIndex: 1,
        pageSize: PAGE_SIZE,
        branchId: selector.branch.userBranchId,
      });
      setPayload({
        ...payload,
        branchId: selector.branch.userBranchId,
      })
    }
    else {
      setAPIPayload({
        ...apiPayload,
        branchId: selector.branch.userBranchId,
      });
    }
  }, [apiPayload,selector.branch.userBranchId]);

  return (
    <Box>
      <DeleteModal
        isOpen={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false);
        }}
        onDelete={onDelete}
      />
      <Container maxW="container.xll" padding={"20px"} paddingBottom={"0px"}>
        <Box>
          <BreadcrumbBox
            route={GET_BREADCRUMB_ROUTES(MENU_NAMES.VIEW_PERMISSIONS)}
          />
        </Box>
        <Box
          color={colors.white}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"20px"}
        >
          <Box>
            <Heading variant={"h1"}>View Permission</Heading>
          </Box>

          <Box display={"flex"} alignItems={"center"} gap={"20px"}>
            <Button onClick={handleToggle} variant={"secondary"}>
              <GoFilter fontSize={"20px"} />
              <Text marginLeft={"5px"} className="btnText">
                Filter
              </Text>
              <Box
                position={"absolute"}
                top={"-19px"}
                right={"-1"}
                backgroundColor={colors.primaryColor}
                color={colors.white}
                borderRadius={"16px"}
                padding={"0px"}
                height={"30px"}
                width={"30px"}
                lineHeight={"30px"}
              >
                {activeFilter}
              </Box>
            </Button>

            <Button
              fontSize={{ base: "12px", sm: "13px", md: "14px", lg: "14px" }}
              variant={"primary"}
              onClick={onOpen}
              //   onClick={() => {
              //     navigate(ROUTE_LINKS[MENU_NAMES.ADD_BRANCH]);
              //   }}
            >
              <IoMdAddCircleOutline fontSize={"20px"} />
              <Text marginLeft={"5px"} className="btnText">
                Create Permission
              </Text>
            </Button>
          </Box>
        </Box>
        <Box
          backgroundColor={colors.white}
          boxShadow={"0px 0px 35px 0px #F5F5F5"}
          borderRadius={"10px"}
          marginBottom={"20px"}
        >
          <Collapse in={show}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const payloadArr = createFilterPayload(filterState);
                const filterSum = getFilterCount(filterState);
                setActiveFilter(filterSum);
                setCurrentPageSize(PAGE_SIZE);
                setCurrentPage(1);
                setAPIPayload({
                  ...apiPayload,
                  filter: payloadArr,
                  pageSize: PAGE_SIZE,
                  pageIndex: 1,
                  branchId: selector.branch.userBranchId,
                });
              }}
            >
              <SimpleGrid
                columns={{ base: "1", sm: "1", md: "3", lg: "3" }}
                spacingX="40px"
                padding={"20px"}
                paddingBottom={"0px"}
              >
                <SelectInput
                  formControl={{ id: "roleId", variant: "floating" }}
                  isRequired={false}
                  // isReadOnly={false}
                  value={filterState.RoleId?.value}
                  name="RoleId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  label={{
                    text: "Role",
                  }}
                  options={role}
                />
                <TextInput
                  type="text"
                  name="Username"
                  value={filterState.Username?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  formControl={{ id: "UserName" }}
                  placeholder={""}
                  variant="flushed"
                  label={{ text: "UserName" }}
                />
                <SelectInput
                  formControl={{ id: "user", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.UserId?.value}
                  name="UserId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  label={{
                    text: "User",
                  }}
                  options={user}
                />
              </SimpleGrid>
              <Box
                display={"flex"}
                justifyContent={"end"}
                paddingRight={"20px"}
                paddingBottom={"20px"}
              >
                <Button
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"primary"}
                  type="submit"
                >
                  Search
                </Button>
                <Button
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"warning"}
                  marginLeft={"10px"}
                  isDisabled={activeFilter > 0 ? false : true}
                  onClick={resetFilterState}
                >
                  Reset
                </Button>
              </Box>
            </form>
          </Collapse>
        </Box>
        <Grid
          name={"permissions"}
          columns={GRID_COLUMNS.VIEW_PERMISSIONS}
          aColumns={columns}
          data={data}
          currentPage={currentPage}
          currentPageSize={currentPageSize}
          permission={props}
          hasDelete={true}
          hasExport={hasExport}
          setColumns={(e) => setColumns(e)}
          isPopupComponent={true}
          setRecordId={(id, props) => {
            setPermissionId(id);
            if (props.isView) {
              setIsView(true);
              setIsEdit(false);
            } else if (props.isEdit) {
              setIsView(false);
              setIsEdit(true);
            } else if (props.isDelete) setIsDeleteOpen(true);
          }}
        />

        <Pagination
          className="pagination-bar"
          currentPage={apiPayload.pageIndex}
          totalCount={totalCount}
          pageSize={apiPayload.pageSize}
          onPageChange={(page) =>{
            setCurrentPage(page);
            setAPIPayload({
              ...apiPayload,
              pageIndex: page,
            })
          }
          }
          handleRowStateChange={(e) => {
            setCurrentPageSize(e.target.value);
            setCurrentPage(1);
            setAPIPayload({
              ...apiPayload,
              pageIndex: 1,
              pageSize: e.target.value,
            });
          }}
          activeBackgroundColor={colors.primaryColor}
          activeTextColor={colors.gray.gray100}
          onChange={onChange}
        />
      </Container>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        minWidth={"20%"}
        isCentered
      >
        <ModalOverlay />
        <Formik
          validationSchema={validationSchema}
          initialValues={initialState}
          enableReinitialize={true}
          onSubmit={async (values) => {
            let finalPayload = {
              name: values.Name,
              isActive: values.isActive,
              branchId: selector.branch.userBranchId,
              userId: values.userId,
              roleId: values.roleId,
              username: values.userName,
              password: values.password,
              user: user.filter((x) => x.value == values.userId)[0].text,
              roleName: role.filter((x) => x.value == values.roleId)[0].text,
            };

            const usernameExits = await api.get(
              `/User/username-exist/${values.userName}/${values.userId}`
            );
            if (usernameExits.data.data) {
              WarningAlert(usernameExits.data.message);
              return;
            }
            if (isEdit) {
              finalPayload.permissionId = permissionId;
              api
                .post(`/Branch/replace-permission`, { ...finalPayload })
                .then((res) => {
                  SuccessAlert("Updated Successfully");
                  getAllRecords();
                  onModelClose();
                })
                .catch((err) => {
                  console.log(err, "err");
                });
              return;
            }
            api
              .post(`/Branch/create-permission`, { ...finalPayload })
              .then((res) => {
                SuccessAlert("Record added Successfully");
                onModelClose();
                // onClose();
                getAllRecords();
              })
              .catch((err) => {
                console.log(err, "err");
              });
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form
              noValidate={true}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <ModalContent>
                <ModalHeader>
                  {isEdit
                    ? "Edit Permission"
                    : isView
                    ? "View Permission "
                    : "Add Permission "}
                </ModalHeader>
                <ModalCloseButton
                  bg={colors.primaryColor}
                  color={colors.white}
                  position={"absolute"}
                  top={"15px"}
                  onClick={() => onModelClose()}
                />
                <ModalBody padding={"20px"}>
                  <SimpleGrid
                    columns={{ base: "1", sm: "1", md: "1", lg: "1" }}
                    spacingX="-3px"
                    spacingY="-6px"
                    gap={"10px"}
                  >
                    <SelectInput
                      name="roleId"
                      formControl={{ id: "roleId", variant: "floating" }}
                      isRequired={true}
                      isReadOnly={isView}
                      value={values.roleId}
                      label={{
                        text: "Role",
                        colors: errors.roleId ? "red !important" : "",
                        variant: errors.roleId ? "errored" : "",
                      }}
                      options={role}
                      variant={errors.roleId ? "errored" : "flushed"}
                      onChange={(e) => handleChange(e)}
                    />
                    <SelectInput
                      name="userId"
                      formControl={{ id: "userId", variant: "floating" }}
                      isRequired={true}
                      isReadOnly={isView}
                      value={values.userId}
                      label={{
                        text: "User",
                        colors: errors.userId ? "red !important" : "",
                        variant: errors.userId ? "errored" : "",
                      }}
                      options={user}
                      variant={errors.userId ? "errored" : "flushed"}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextInput
                      type="text"
                      isRequired={true}
                      name="userName"
                      value={values.userName}
                      label={{
                        text: "Username",
                        colors: errors.userName ? "red !important" : "",
                        variant: errors.userName ? "errored" : "",
                      }}
                      onChange={(e) => handleChange(e)}
                      placeholder={""}
                      variant={errors.userName ? "errored" : "flushed"}
                      isReadOnly={isView}
                    />
                    <TextInput
                      type="password"
                      isRequired={true}
                      name="password"
                      value={values.password}
                      label={{
                        text: "Password",
                        colors: errors.password ? "red !important" : "",
                        variant: errors.password ? "errored" : "",
                      }}
                      onChange={(e) => handleChange(e)}
                      placeholder={""}
                      variant={errors.password ? "errored" : "flushed"}
                      isReadOnly={isView}
                      icon={{
                        visible: true,
                      }}
                    />
                  </SimpleGrid>
                </ModalBody>
                <ModalFooter
                  justifyContent={"space-between"}
                  borderTop={"1px solid #EDEDED "}
                >
                  <Button
                    variant={"secondaryOutline"}
                    onClick={() => onModelClose()}
                  >
                    {isView ? "Back" : "Discard"}
                  </Button>
                  {!isView && (
                    <Button variant={"primary"} type="submit">
                      {isLoading ? (
                        <CircularProgress
                          isIndeterminate
                          size="24px"
                          color={colors.white}
                        />
                      ) : isEdit ? (
                        "Save"
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  )}
                </ModalFooter>
              </ModalContent>
            </Form>
          )}
        </Formik>
      </Modal>
    </Box>
  );
};
export default ViewPermission;
