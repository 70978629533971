
import React, { useEffect, useState } from "react";
import Pagination from "../../theme/components/pagination/pagination";
import { GRID_COLUMNS } from "../../core/utils/grid-columns";
import { MENU_NAMES, ROLES, PAGE_SIZE } from "../../core/utils/constants";
import { useSelector } from "react-redux";
import { colors } from "../../theme/styles/colors";
import api from "../../core/api";
import {
  CREATE_RESPONSE,
} from "../../core/utils/common-function";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "../../theme/components/grid/grid";
// import { Navigate } from "react-router-dom";

function NewImmigartionFiles(props) {
  const selector = useSelector((_) => _);
  const params = useLocation();
  const navigate = useNavigate();
  const [tableRowData, setTableRowData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [show, setShow] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(props.apiPayload.pageIndex);
  const [currentPageSize, setCurrentPageSize] = useState(props.apiPayload.pageSize);
  const hasExport =
    selector.auth.login.RoleId == ROLES.SYSTEM_ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.BRANCH_HEAD;
  const [columns, setColumns] = useState(
    GRID_COLUMNS.DAILY_ROUTINE_NEWFILE.filter((x) => x.Active && x.Visibility)
  );
  props.apiPayload.pageSize = currentPageSize;
  props.apiPayload.pageIndex = currentPage;
  const onChange = (page) => {
    setCurrentPage(page);
    props.setAPIPayload({
      ...props.apiPayload,
      pageIndex: page,
    });
  };

  useEffect(() => {
    setCurrentPageSize(selector.paginationData.pagination.pageSize);
    setCurrentPage(selector.paginationData.pagination.pageIndex);
  }, [props.tabIndex]);

  useEffect(() => {
    if (props.apiPayload.branchId != selector.branch.userBranchId) {
      setCurrentPageSize(PAGE_SIZE);
      setCurrentPage(1);
    }
    else if ((props.tabIndex == 1 && props.apiPayload.branchId != "") || props.apiPayload.branchId != selector.branch.userBranchId)
      getAllRecords();
  }, [props.apiPayload, selector.branch.userBranchId, props.tabIndex, currentPage, currentPageSize]);

  // useEffect(() => {
  //   if (props.tabIndex == 2 && props.apiPayload.branchId != "") 
  //   getAllRecords();
  // }, [currentPage,currentPageSize]);

  const getAllRecords = () => {
    props.apiPayload.pageSize = currentPageSize;
    props.apiPayload.pageIndex = currentPage;
    api
      .post("/Dashboard/getImmigrationFiles", props.apiPayload)
      .then((res) => {
        setTotalCount(res.data.data.total);
        setDataSource(res.data.data.data);
        setData(
          CREATE_RESPONSE(
            GRID_COLUMNS.DAILY_ROUTINE_NEWFILE,
            res.data.data.data
          )
        );
      })
      .catch((err) => { });
  };

  useEffect(() => {
    setData(CREATE_RESPONSE(columns, dataSource));
  }, [columns]);

  return (
    <div>
      <Grid
        name={"new_files"}
        columns={GRID_COLUMNS.DAILY_ROUTINE_NEWFILE}
        aColumns={columns}
        data={data}
        navigateUrl={props.isForDashboard ? ROUTE_LINKS[MENU_NAMES.DASHBOARD] : ROUTE_LINKS[MENU_NAMES.DAILY_ROUTINE]}
        currentPage={currentPage}
        currentPageSize={currentPageSize}
        tabIndex={props.tabIndex}
        permission={props.permission}
        hasExport={hasExport}
        setColumns={(e) => setColumns(e)}
        viewRoute={ROUTE_LINKS[MENU_NAMES.VIEW_IMMIGRATION]}
        editRoute={ROUTE_LINKS[MENU_NAMES.EDIT_IMMIGRATION]}
      />
      <Pagination
        className="pagination-bar"
        currentPage={props.apiPayload.pageIndex}
        totalCount={totalCount}
        pageSize={props.apiPayload.pageSize}
        onPageChange={(page) => {
          setCurrentPage(page);
          props.setAPIPayload({
            ...props.apiPayload,
            pageIndex: page,
          })
        }
        }
        handleRowStateChange={(e) => {
          setCurrentPageSize(e.target.value);
          setCurrentPage(1);
          props.setAPIPayload({
            ...props.apiPayload,
            pageIndex: 1,
            pageSize: e.target.value,
          });
        }}
        activeBackgroundColor={colors.primaryColor}
        activeTextColor={colors.gray.gray100}
        onChange={onChange}
      />
    </div>
  );
}

export default NewImmigartionFiles;
