import {
  Box,
  Text,
  Stack,
  Collapse,
  Icon,
  useColorModeValue,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Alert,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../styles/colors";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLayout, setPagination } from "../../../core/store/actions";
import { NAVBAR_ITEMS } from "../../../core/utils/navbar-items";
import { checkPermission } from "../../../core/utils/common-function";
import { PAGE_SIZE } from "../../../core/utils/constants";

const DesktopNav = () => {
  const location = useLocation();
  const selector = useSelector((_) => _);
  const navigate = useNavigate();
  const routePermission = selector.auth.login.Permission;
  const routeParam = window.location.pathname;
  const currentRoute = routeParam?.split("/").filter(Boolean)[0]?.replace(/[^a-z0-9]/gi, " ");
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  const dispatch = useDispatch();
  useEffect(() => { });

  return (
    <Stack
      direction={"column"}
      spacing={0}
      borderStyle={"none"}
      borderWidth={"0"}
    >
      {/* {(NAVBAR_ITEMS, "NAVBAR_ITEMS")} */}
      {NAVBAR_ITEMS.map((navItem, index) => (
        <Box key={navItem.label} cursor={"pointer"} flexDirection={"column"}>
          {checkPermission(routePermission, navItem) && (
            <Accordion
              allowToggle
              allowMultiple={false}
              borderStyle={"none"}
              borderWidth={"0"}
            >
              <AccordionItem borderStyle={"none"} borderWidth={"0"}>
                <AccordionButton justifyContent={"left"}
                  display={"flex"}
                  gap={"10px"}
                  zIndex={"2"}
                  alignItems={"center"}
                  flexDirection={"row"}
                  width={"100%"}
                  key={index}
                  position={"relative"}
                  cursor={"pointer"}
                  padding={{
                    base: "0px",
                    sm: "0px",
                    md: "7px 5px",
                    lg: "12px 12px 12px 20px",
                  }}
                  _hover={{
                    background: "#f0ac0029",
                    borderTopRightRadius: "30px",
                    borderBottomRightRadius: "30px",
                  }}
                  onClick={() => {
                    dispatch(setPagination({ pageIndex: 1, pageSize: PAGE_SIZE, tabIndex: 0 }));
                    if (navItem.isRouteable == undefined) {
                      navigate(navItem.path);
                    }
                  }}
                  _before={
                    currentRoute == navItem.label.toLowerCase()
                      ? {
                        content: `""`,
                        position: "absolute",
                        width: "0px",
                        height: "calc(100% + 0px)",
                        backgroundColor: colors.primaryColor,
                        top: "0px",
                        left: "0px",
                        padding: "0px",
                      }
                      : {}
                  }
                  _after={
                    currentRoute == navItem.label.toLowerCase()
                      ? {
                        content: `""`,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: "0px",
                        left: "0px",
                        zIndex: "-1",
                        background: "#f0ac0029",
                        borderTopRightRadius: "30px",
                        borderBottomRightRadius: " 30px",
                      }
                      : {}
                  }
                >

                  {navItem.icon}
                  <Text
                    fontSize={{
                      base: "13px",
                      sm: "13px",
                      md: "13px",
                      lg: "13px",
                    }}
                    color={
                      currentRoute == navItem.label.toLowerCase()
                        ? colors.primaryColor
                        : ""
                    }
                  >
                    {navItem.label}
                  </Text>

                  {navItem.children && <AccordionIcon />}
                </AccordionButton>

                <AccordionPanel pb={4} padding={"0px 0px 0px 42px !important"}>
                  <Stack position={"relative!important"}>
                    {navItem.children &&
                      navItem.children.map((child) => (
                        <>
                          {checkPermission(routePermission, child) && (
                            <DesktopSubNav key={child.label} {...child} />
                          )}
                        </>
                      ))}
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel, path, setActiveRoute }) => {
  const navigate = useNavigate();
  const activeCard = {
    borderRadius: "0px",
    color: "#64748B",
    fontWeight: "600",
    position: "relative",
    fontSize: "14px",
    padding: "5px",
    backgroundColor: "#ffffff",
    columns: { base: "1", sm: "1", md: "2", lg: "2" },
  };
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleToggle = () => setShow(!show);
  return (
    <Box
      as="a"
      href={href}
      role={"group"}
      position={"relative"}
      p={2}
      rounded={"md"}
      {...activeCard}
      onClick={() => {
        dispatch(setPagination({ pageIndex: 1, pageSize: PAGE_SIZE, tabIndex: 0 }));
        navigate(path);
      }}
    >
      <Stack direction={"row"} align={"left"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{}}
            fontSize={"12px"}
            fontWeight={400}
            color={"#000000"}
            _hover={{ color: "#F0AC00 !important" }}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
      </Stack>
    </Box>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAVBAR_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Box
        py={2}
        as="a"
        href={href ?? "#"}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Box as="a" key={child.label} py={2} href={child.href}>
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default function WithSubnavigation() {
  const { onToggle } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const selector = useSelector((_) => _);
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(
      changeLayout(
        show
          ? {
            sidebar: "sidebar hide",
            wrapper: "wrapper full-wrapper",
          }
          : {
            sidebar: "sidebar",
            wrapper: "wrapper",
          }
      )
    );
  }, [show]);

  return (
    <Box
      display={"flex"}
      marginTop={"0px"}
      className={selector.mainReducer.layout.sidebar}
    >
      <Box
        className={show ? "navbarBox show" : "navbarBox"}
        borderRight={"0px solid #e2e8f0"}
      >
        <DesktopNav />
      </Box>
    </Box>
  );
}
