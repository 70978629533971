import { ChevronRightIcon } from "@chakra-ui/icons";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { colors } from "../../styles/colors";
import { PAGE_SIZE } from "../../../core/utils/constants";
import { setPagination } from "../../../core/store/actions";
import { useDispatch } from "react-redux";

const BreadcrumbBox = ({ route = [] }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Breadcrumb
      separator={<ChevronRightIcon />}
      size="xs"
      fontSize="xs"
      spacing="3px"
      marginBottom={"5px"}
    >
      {route?.map((rout, index) => (
        <BreadcrumbItem
          key={index}
          isCurrentPage={index == route.length - 1}
          color={index == route.length - 1 ? colors.primaryColor : ""}
        >
          <BreadcrumbLink
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(setPagination({pageIndex:1,pageSize:PAGE_SIZE,tabIndex:0}));
              if (rout.isRouteable) {
                if (index != route.length - 1) navigate(rout.path);
              }
            }}
          >
            {rout.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbBox;
