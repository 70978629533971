import { combineReducers } from "@reduxjs/toolkit";
import mainReducer from "./reducer";
import authReducer from "./sign-in";
import { branchReducer } from "./branch";
import pendingDocumentsReducer from "./pending-documents";
import OTPReducer from "./otpReducer";
import PaginationReducer from "./pagination";

export default combineReducers({
  mainReducer,
  auth: authReducer,
  branch: branchReducer,
  pendingDocuments: pendingDocumentsReducer,
  otpFile: OTPReducer,
  paginationData: PaginationReducer
});
