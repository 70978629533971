import React, { useState } from "react";
import {
  Container,
  Box,
  SimpleGrid,
  Heading,
  Collapse,
  Button,
  Checkbox,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  FormLabel,
  Badge,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../theme/styles/colors";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import TextInput from "../../theme/components/input/text-input";
import Pagination from "../../theme/components/pagination/pagination";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import {
  ADMIN_ALLOWED_DOCUMENT,
  CASE_STATUS,
  INTAKE,
  MENU_NAMES,
  MODULE_ENUMS,
  PAGE_SIZE,
  ROLES,
} from "../../core/utils/constants";
import { GRID_COLUMNS } from "../../core/utils/grid-columns";
import SelectInput from "../../theme/components/input/select-input";
import { GoFilter } from "react-icons/go";
import {
  getFilterCount,
  convertDateFormat,
  createFilterPayload,
  getAll,
  handleFilterChange,
  handleResetState,
  deleteById,
  getById,
  CREATE_RESPONSE,
  fileTypeColors,
  handleActiveFilter,
  createFilterState,
} from "../../core/utils/common-function";
import api from "../../core/api";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import { Form, Formik } from "formik";
import * as yup from "yup";
import SCHEMA_VALIDATOR from "../../core/utils/schema-validator";
import { useSelector } from "react-redux";
import TextArea from "../../theme/components/input/text-area";
import {
  SuccessAlert,
} from "../../core/utils/common-sweet-alert";
import DeleteModal from "../../theme/components/modals/delete-modal";
import Grid from "../../theme/components/grid/grid";
import { GRID_OTHER_ACTIONS } from "../../core/utils/grid-other-action";
import { IMMIGRATION_PAYLOAD } from "./immigration-payload";

const Immigration = (props) => {
  const location = useLocation();
  const selector = useSelector((_) => _);
  const currentRoute = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const moduleId = selector.auth.login.Permission?.find((x) => x.route == currentRoute)?.moduleId;
  const navigate = useNavigate();
  const [isLastActiveFilter, setIsLastActiveFilter] = useState(0);
  const [current, setCurrent] = useState(3);
  const [currentPage, setCurrentPage] = useState(selector.paginationData.pagination.pageIndex);
  const [currentPageSize, setCurrentPageSize] = useState(selector.paginationData.pagination.pageSize);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [minToDate, setMinToDate] = useState(
    convertDateFormat(new Date(), "yyyy-mm-dd")
  );
  const prevDate = new Date();
  prevDate.setDate(prevDate.getDate() - 1);
  const [maxToDate, setMaxToDate] = useState(
    convertDateFormat(prevDate, "yyyy-mm-dd")
  );
  const [isToDate, setIsToDate] = useState(true);
  const [district, setDistrict] = useState([]);
  const [board, setBoard] = useState([]);
  const [visaType, setVisaType] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [fileDocuments, setFileDocuments] = useState([]);
  const [visaCountry, setVisaCountry] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reference, setReference] = useState([]);
  const [college, setCollege] = useState([]);
  const [course, setCourse] = useState([]);
  const [caseStatus, setCaseStatus] = useState([]);
  const [fileType, setFileType] = useState([]);
  const [fileMode, setFileMode] = useState([]);
  const [fundType, setFundType] = useState([]);
  const [immigrationFileId, setImmigrationFileId] = useState(null);
  const [isUpdateStatusModalOpen, setUpdateStatusModal] = useState(false);
  const [isStatusReason, setStatusReason] = useState(false);
  const [isRemarkOpen, setRemarkOpen] = useState(false);
  const [isDocumentOpen, setDocumentOpen] = useState(false);
  const [enquiryFileId, setEnquiryFileId] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [fileDocument, setFileDocument] = useState([]);
  const [completeStage, setCompleteStage] = useState([]);
  const [singleUserRecord, setSingleUserRecord] = useState({});
  const [singleFileDetail, setSingleFileDetail] = useState({
    name: "",
    fileNumber: "",
  });
  const [activeFilter, setActiveFilter] = useState(0);
  const [pendingDocuments, setPendingDocuments] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);
  const [allRecords, setAllRecords] = useState([]);
  const [userName, setUserName] = useState("");
  const [activeCheckbox, setActiveCheckbox] = useState([]);
  const hasExport =
    selector.auth.login.RoleId == ROLES.SYSTEM_ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.BRANCH_HEAD;
  const [columns, setColumns] = useState(
    GRID_COLUMNS.IMMIGRATION.filter((x) => x.Active && x.Visibility)
  );
  const [initialState, setInitialState] = useState({
    immigrationFileId: null,
    remark: "",
  });
  const [show, setShow] = useState(false);
  const [apiPayload, setAPIPayload] = useState({
    filter: [],
    pageSize: currentPageSize,
    pageIndex: currentPage,
    branchId: props.branchId,
  });
  const [immigrationState, setImmigrationState] = useState({
    caseStatusId: "",
  });
  const [manageDocument, setManageDocument] = useState({
    userName: "",
  });
  const [payload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
    branchId: props.branchId,
  });
  const [filterState, setFilterState] = useState({
    name: "",
    passoutYear: "",
    address: "",
    expiry: "",
    completionDate: "",
    visaCollegeId: "",
    fromDate: "",
    toDate: "",
    fileNumber: "",
    branchId: "",
    statusId: "",
    districtId: "",
    qualStreamId: "",
    boardId: "",
    visaTypeId: "",
    countryId: "",
    counsellorId: "",
    referenceId: "",
    visaCourseId: "",
    intakeId: "",
    caseStatusId: "",
    pendingStageId: "",
    completeStageId: "",
    fileTypeId: "",
    fileModeId: "",
    fundTypeId: "",
  });
  const validationSchema = yup.object({
    remark: SCHEMA_VALIDATOR.SPECIAL_CHARECTERS_VALIDATOR,
  });
  const manageDocumentValidationSchema = yup.object({
    userName: SCHEMA_VALIDATOR.ONLY_ALPHABETS_VALIDATOR,
  });
  const onChange = (page) => {
    setCurrent(page);
  };

  const handleToggle = () => {
    setShow(!show);
  };

  const getAllRecords = () => {
    api
      .post("/ImmigrationFile/getAll", apiPayload)
      .then((res) => {
        setTotalCount(res.data.data.total);
        setDataSource(res.data.data.data);
        setData(CREATE_RESPONSE(GRID_COLUMNS.IMMIGRATION, res.data.data.data));
      })
      .catch((err) => { });
  };

  const onUpdateModalClose = () => {
    setUpdateStatusModal(false);
  };
  const onDocumentClose = () => {
    setDocumentOpen(false);
    setActiveCheckbox([]);
  };
  const onRemarkClose = () => {
    setRemarkOpen(false);
  };

  const getSingleImmigrationFile = async (singleImmigrationFileId) => {
    const immigrationFileId = singleImmigrationFileId;
    const singleFile = (await getById("ImmigrationFile", immigrationFileId))
      .data;
    setSingleFileDetail(singleFile);
    setAllRecords(singleFile.fileDocumentStatus);
    setDocumentOpen(true);
    if (singleFile.fileDocumentStatus.length >= 0) {
      let checkedDocs = singleFile.fileDocumentStatus.map((item) => {
        return item.fileDocumentId;
      });
      singleFile.fileDocumentStatus = singleFile.fileDocumentStatus.map(
        (item) => {
          return item.fileDocumentId;
        }
      );
      setFileDocuments(checkedDocs);
    }
  };

  const onModelClose = () => {
    setImmigrationFileId(null);
    onClose();
  };

  const onDelete = async () => {
    await deleteById("ImmigrationFile", immigrationFileId);
    setIsDeleteOpen(false);
    getAllRecords();
    onModelClose();
  };

  const getAllPromises = () => {
    const District = getAll("District", payload);
    const VisaCountry = getAll("VisaCountry", payload);
    const Reference = getAll("Reference", payload);
    const VisaType = getAll("VisaType", payload);
    const Board = getAll("Board", payload);
    const College = getAll("VisaCollege", payload);
    const Course = getAll("VisaCourse", payload);
    const CaseStatus = getAll("CaseStatus", payload);
    const FileType = getAll("FileType", payload);
    const FileMode = getAll("FileMode", payload);
    const FundType = getAll("FundType", payload);
    const FileDocument1 = getAll("FileDocument", payload);
    const CompleteStage = getAll("FileDocument", payload);
    Promise.all([
      VisaCountry,
      VisaType,
      Reference,
      District,
      Board,
      College,
      Course,
      CaseStatus,
      FileType,
      FileMode,
      FundType,
      FileDocument1,
      CompleteStage,
    ])
      .then((res) => {
        const [
          visaCountryResponse,
          visaTypeResponse,
          referenceResponse,
          districtResponse,
          boardResponse,
          collegeResponse,
          courseResponse,
          caseStatusResponse,
          fileTypeResponse,
          fileModeResponse,
          fundTypeResponse,
          fileDocumentResponse,
          completeStageResponse,
        ] = res;
        const VisaCountry = visaCountryResponse.data.data.map(
          (visaCountry) => ({
            text: visaCountry.name,
            value: visaCountry.visaCountryId,
          })
        );
        const Reference = referenceResponse.data.data.map((reference) => ({
          text: reference.name,
          value: reference.referenceId,
        }));
        const VisaType = visaTypeResponse.data.data.map((visaType) => ({
          text: visaType.name,
          value: visaType.visaTypeId,
        }));
        const District = districtResponse.data.data.map((district) => ({
          text: district.name,
          value: district.districtId,
        }));
        const Board = boardResponse.data.data.map((board) => ({
          text: board.name,
          value: board.boardId,
        }));
        const College = collegeResponse.data.data.map((college) => ({
          text: college.name,
          value: college.visaCollegeId,
        }));
        const Course = courseResponse.data.data.map((course) => ({
          text: course.name,
          value: course.visaCourseId,
        }));
        const CaseStatus = caseStatusResponse.data.data.map((caseStatus) => ({
          text: caseStatus.name,
          value: caseStatus.caseStatusId,
        }));
        const FileType = fileTypeResponse.data.data.map((fileType) => ({
          text: fileType.name,
          value: fileType.fileTypeId,
        }));
        const FileMode = fileModeResponse.data.data.map((fileMode) => ({
          text: fileMode.name,
          value: fileMode.fileModeId,
        }));
        const FundType = fundTypeResponse.data.data.map((fundType) => ({
          text: fundType.name,
          value: fundType.fundTypeId,
        }));
        const FileDocument = fileDocumentResponse.data.data.map(
          (fileDocument) => ({
            text: fileDocument.name,
            value: fileDocument.fileDocumentId,
            name: fileDocument.name,
          })
        );
        const CompleteStage = completeStageResponse.data.data.map(
          (completeStage) => ({
            text: completeStage.name,
            value: completeStage.fileDocumentId,
          })
        );
        setVisaCountry(VisaCountry);
        setVisaType(VisaType);
        setReference(Reference);
        setDistrict(District);
        setBoard(Board);
        setCollege(College);
        setCourse(Course);
        setCaseStatus(CaseStatus);
        setFileType(FileType);
        setFileMode(FileMode);
        setFundType(FundType);
        setFileDocument(FileDocument);
        setCompleteStage(CompleteStage);
        const updatedDocumentResponse = fileDocumentResponse.data.data.map(
          (item) => ({ ...item, userName: selector.auth.login.Name })
        );
        setPendingDocuments(updatedDocumentResponse);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const resetFilterState = () => {
    setCurrentPage(1);
    setCurrentPageSize(PAGE_SIZE);
    setAPIPayload({
      ...apiPayload,
      filter: [],
      pageIndex: 1,
      pageSize: PAGE_SIZE,
    });
    handleActiveFilter('delete', moduleId)
    handleResetState(filterState, setFilterState);
    setActiveFilter(0);
  };

  const immigrationStateValidationSchema = yup.object({
    caseStatusId: SCHEMA_VALIDATOR.GUID_VALIDATOR,
  });

  const getNameAndDate = (currentDocId) => {
    try {
      return allRecords.filter((item) =>
        item.fileDocumentId.includes(currentDocId)
      );
    } catch (error) {
      console.log(error, "error");
    }
  };
  const lastActiveFilter = async () => {
    let filledFilter = [];
    const lastFilter = await handleActiveFilter('get', moduleId);
    if (lastFilter) {
      filledFilter = JSON.parse(lastFilter.data.data);
      if (filledFilter) {
        setCurrentPage(1);
        setCurrentPageSize(PAGE_SIZE);
        createFilterState(filledFilter, setFilterState, setActiveFilter);
      }
    }
    setAPIPayload({
      ...apiPayload,
      filter: filledFilter ? filledFilter : [],
      pageSize: PAGE_SIZE,
      pageIndex: 1,
    });
    setIsLastActiveFilter(1);
  };

  useEffect(() => {
    //  lastActiveFilter();
  }, [])

  useEffect(() => {
    setData(CREATE_RESPONSE(columns, dataSource));
  }, [columns]);

  useEffect(() => {
    document.title = "Immigration | Digital Filing";
    getAllPromises();
  }, [payload]);

  useEffect(() => {
    if (isLastActiveFilter == 0)
      lastActiveFilter();

    else if (apiPayload.branchId != "" && apiPayload.branchId == props.branchId)
      getAllRecords();

    else if (apiPayload.branchId != props.branchId) {
      setCurrentPage(1);
      setCurrentPageSize(PAGE_SIZE);
      setAPIPayload({
        ...apiPayload,
        pageIndex: 1,
        pageSize: PAGE_SIZE,
        branchId: props.branchId,
      });
    }
    else {
      setAPIPayload({
        ...apiPayload,
        branchId: props.branchId,
      });
    }
  }, [apiPayload, props.branchId]);

  return (
    <Box>
      <DeleteModal
        isOpen={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false);
        }}
        onDelete={onDelete}
      />
      <Container maxW="container.xll" padding={"20px"} paddingBottom={"0px"}>
        <Box>
          <BreadcrumbBox
            route={GET_BREADCRUMB_ROUTES(MENU_NAMES.IMMIGRATION, true)}
          />
        </Box>
        <Box
          color={colors.white}
          display={"flex"}
          justifyContent="space-between"
          alignItems={"center"}
          marginBottom={"20px"}
        >
          <Box>
            <Heading variant={"h2"}>Immigration</Heading>
          </Box>
          <Button onClick={handleToggle} variant={"secondary"}>
            <GoFilter fontSize={"20px"} />
            <Text marginLeft={"5px"} className="btnText">
              Filter
            </Text>
            <Box
              position={"absolute"}
              top={"-19px"}
              right={"-1"}
              backgroundColor={colors.primaryColor}
              color={colors.white}
              borderRadius={"16px"}
              padding={"0px"}
              height={"30px"}
              width={"30px"}
              lineHeight={"30px"}
            >
              {activeFilter}
            </Box>
          </Button>
        </Box>
        <Box
          backgroundColor={colors.white}
          boxShadow={"0px 0px 35px 0px #F5F5F5"}
          borderRadius={"10px"}
          marginBottom={"20px"}
        >
          <Collapse in={show}>
            <form
              noValidate={true}
              onSubmit={(e) => {
                e.preventDefault();
                const payloadArr = createFilterPayload(
                  filterState,
                  IMMIGRATION_PAYLOAD.FILTER_KEYS
                );

                const filterSum = getFilterCount(filterState);
                setActiveFilter(filterSum);
                setCurrentPage(1);
                setCurrentPageSize(PAGE_SIZE);
                setAPIPayload({
                  ...apiPayload,
                  filter: payloadArr,
                  pageSize: PAGE_SIZE,
                  pageIndex: 1,
                });

                const jsonString = JSON.stringify(payloadArr);
                handleActiveFilter('post', '', { moduleId: moduleId, StateJson: jsonString })

              }}
            >
              <SimpleGrid
                columns={{ base: "1", sm: "1", md: "6", lg: "6" }}
                spacingX="40px"
                padding={"20px"}
                paddingBottom={"0px"}
              >
                <TextInput
                  type="number"
                  name="FileNumber"
                  value={filterState.FileNumber?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "File No.")
                  }
                  formControl={{ id: "File No." }}
                  placeholder={""}
                  variant="flushed"
                  label={{ text: "File No." }}
                />
                <TextInput
                  type="text"
                  name="Name"
                  value={filterState.Name?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Application Name")
                  }
                  variant="flushed"
                  placeholder={""}
                  label={{ text: "Application Name" }}
                />
                <TextInput
                  type="text"
                  placeholder={""}
                  name="Address"
                  value={filterState.Address?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Address")
                  }
                  marginTop="20px"
                  formControl={{ id: "address" }}
                  label={{ text: "Address" }}
                />
                <TextInput
                  type="date"
                  name="fromDate"
                  value={filterState.fromDate?.value}
                  onChange={(e) => {
                    let D = new Date(e.target.value).setDate(
                      new Date(e.target.value).getDate() + 1
                    );
                    setMinToDate(convertDateFormat(D, "yyyy-mm-dd"));
                    handleFilterChange(e, filterState, setFilterState, "From");
                    if (e.target.value != "") {
                      setIsToDate(false);
                    }
                  }}
                  placeholder={""}
                  variant="flushed"
                  label={{ text: "From" }}
                />
                <TextInput
                  type="date"
                  isDisabled={isToDate}
                  name="toDate"
                  minVal={minToDate}
                  value={filterState.toDate?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "To")
                  }
                  placeholder={""}
                  variant="flushed"
                  label={{ text: "To" }}
                />
                <TextInput
                  type="text"
                  name="PassoutYear"
                  value={filterState.PassoutYear?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Passout Year")
                  }
                  variant="flushed"
                  placeholder={""}
                  label={{ text: "Passout Year" }}
                />
                <SelectInput
                  formControl={{ id: "districtId", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.DistrictId?.value}
                  name="DistrictId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "District")
                  }
                  label={{
                    text: "Districtsssss",
                  }}
                  options={district}
                  showAllOption={true}
                />
                <SelectInput
                  formControl={{ id: "boardId", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.BoardId?.value}
                  name="BoardId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Board")
                  }
                  label={{
                    text: "Board",
                  }}
                  options={board}
                  showAllOption={true}
                />
                <SelectInput
                  formControl={{ id: "visaId", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.VisaTypeId?.value}
                  name="VisaTypeId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Visa Type")
                  }
                  label={{
                    text: "Visa Type",
                  }}
                  options={visaType}
                  showAllOption={true}
                />
                <SelectInput
                  formControl={{ id: "countryId", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.VisaCountryId?.value}
                  name="VisaCountryId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Country")
                  }
                  label={{
                    text: "Country",
                  }}
                  options={visaCountry}
                  showAllOption={true}
                />
                <SelectInput
                  formControl={{ id: "referenceId", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.ReferenceId?.value}
                  name="ReferenceId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Reference")
                  }
                  label={{
                    text: "Reference",
                  }}
                  options={reference}
                  showAllOption={true}
                />
                <SelectInput
                  formControl={{ id: "visaCollegeId", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.VisaCollegeId?.value}
                  name="VisaCollegeId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Visa College")
                  }
                  label={{
                    text: "Visa College",
                  }}
                  options={college}
                  showAllOption={true}
                />
                <SelectInput
                  formControl={{ id: "visaCourseId", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.VisaCourseId?.value}
                  name="VisaCourseId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Visa Course")
                  }
                  label={{
                    text: "Visa Course",
                  }}
                  options={course}
                  showAllOption={true}
                />
                <SelectInput
                  formControl={{ id: "intakeId", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.IntakeId?.value}
                  name="IntakeId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Intake")
                  }
                  label={{
                    text: "Intake",
                  }}
                  options={INTAKE}
                  showAllOption={true}
                />
                <SelectInput
                  formControl={{ id: "caseStatusId", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.CaseStatusId?.value}
                  name="CaseStatusId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Case Status")
                  }
                  label={{
                    text: "Case Status",
                  }}
                  options={caseStatus}
                  showAllOption={true}
                />
                <SelectInput
                  formControl={{ id: "pendingStageId", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.pendingStageId?.value}
                  name="pendingStageId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Pending Stage")
                  }
                  label={{
                    text: "Pending Stage",
                  }}
                  options={fileDocument}
                  showAllOption={true}
                />
                <SelectInput
                  formControl={{ id: "completeStageId", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.CompleteStageId?.value}
                  name="CompleteStageId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Complete Stage")
                  }
                  label={{
                    text: "Complete Stage",
                  }}
                  options={completeStage}
                  showAllOption={true}
                />
                <SelectInput
                  formControl={{ id: "fileTypeId", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.FileTypeId?.value}
                  name="FileTypeId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "File Type")
                  }
                  label={{
                    text: "File Type",
                  }}
                  options={fileType}
                  showAllOption={true}
                />
                <SelectInput
                  formControl={{ id: "fileModeId", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.FileModeId?.value}
                  name="FileModeId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "File Mode")
                  }
                  label={{
                    text: "File Mode",
                  }}
                  options={fileMode}
                  showAllOption={true}
                />
                <SelectInput
                  formControl={{ id: "fundTypeId", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.FundTypeId?.value}
                  name="FundTypeId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState, "Fund Type")
                  }
                  label={{
                    text: "Fund Type",
                  }}
                  options={fundType}
                  showAllOption={true}
                />
              </SimpleGrid>
              <Box
                display={"flex"}
                justifyContent={"end"}
                paddingRight={"20px"}
                paddingBottom={"20px"}
              >
                <Button
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"primary"}
                  type="submit"
                >
                  Search
                </Button>
                <Button
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"warning"}
                  marginLeft={"10px"}
                  isDisabled={activeFilter > 0 ? false : true}
                  onClick={resetFilterState}
                >
                  Reset
                </Button>
              </Box>
            </form>
          </Collapse>
        </Box>

        <Grid
          name={"immigration"}
          columns={GRID_COLUMNS.IMMIGRATION}
          aColumns={columns}
          data={data}
          currentPage={currentPage}
          navigateUrl={ROUTE_LINKS[MENU_NAMES.IMMIGRATION]}
          currentPageSize={currentPageSize}
          permission={props}
          hasDelete={true}
          hasExport={hasExport}
          setColumns={(e) => setColumns(e)}
          viewRoute={ROUTE_LINKS[MENU_NAMES.VIEW_IMMIGRATION]}
          editRoute={ROUTE_LINKS[MENU_NAMES.EDIT_IMMIGRATION]}
          isPopupNavComponent={true}
          otherActions={GRID_OTHER_ACTIONS.IMMIGRATION}
          modeTypes={fileTypeColors}
          setRecordId={(id, props) => {
            setImmigrationFileId(id);
            if (props.isDelete) setIsDeleteOpen(true);
            else if (props.updateStatus) {
              setImmigrationState({
                ...immigrationState,
                caseStatusId: props.caseStatusId,
              });
              setStatusReason(false);
              setUpdateStatusModal(true);
            } else if (props.manageDocument) {
              setSingleUserRecord(true);
              setEnquiryFileId(id);
              getSingleImmigrationFile(id);
            } else if (props.specialRemarks) {
              setRemarkOpen(true);
            }
          }}
        />
        <Pagination
          className="pagination-bar"
          currentPage={apiPayload.pageIndex}
          totalCount={totalCount}
          pageSize={apiPayload.pageSize}
          onPageChange={(page) => {
            setCurrentPage(page);
            setAPIPayload({
              ...apiPayload,
              pageIndex: page,
            })
          }
          }
          handleRowStateChange={(event) => {
            setCurrentPageSize(event.target.value);
            setCurrentPage(1);
            setAPIPayload({
              ...apiPayload,
              pageIndex: 1,
              pageSize: event.target.value,
            });
          }}
          activeBackgroundColor={colors.primaryColor}
          activeTextColor={colors.gray.gray100}
          onChange={onChange}
        />
      </Container>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isRemarkOpen}
        onClose={onRemarkClose}
        minWidth={"20%"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialState}
            enableReinitialize={true}
            onSubmit={(values) => {
              let payload = {
                immigrationFileId: immigrationFileId,
                remark: values.remark,
              };
              api
                .post("ImmigrationFile/create-remark", payload)
                .then((res) => {
                  SuccessAlert("Remark added sucessfully");
                  onRemarkClose();
                  getAllRecords();
                  setInitialState({
                    immigrationFileId: "",
                    remark: "",
                  });
                  setImmigrationFileId(null);
                })
                .catch((err) => {
                  console.log(err, "err");
                });
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form
                noValidate={true}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <ModalHeader borderBottom={"1px solid #EDEDED"}>
                  Add Special Remark
                </ModalHeader>
                <ModalCloseButton
                  bg={colors.primaryColor}
                  color={colors.white}
                  position={"absolute"}
                  top={"15px"}
                  onClick={onModelClose}
                />
                <ModalBody padding={"20px"}>
                  <SimpleGrid
                    columns={{ base: "1", sm: "1", md: "1", lg: "1" }}
                    spacingX="-3px"
                    spacingY="-6px"
                    gap={"10px"}
                  >
                    <TextArea
                      name="remark"
                      value={values.remark}
                      placeholder={""}
                      resize="auto"
                      rows={20}
                      w={"100%"}
                      height="unset"
                      minH={"300px!important"}
                      label={{
                        text: "Special Remark",
                        colors: errors.remark ? "red !important" : "",
                        variant: errors.remark ? "errored" : "",
                      }}
                      onChange={handleChange}
                      variant={errors.remark ? "errored" : "flushed"}
                    />
                  </SimpleGrid>
                </ModalBody>
                <ModalFooter
                  justifyContent={"space-between"}
                  borderTop={"1px solid #EDEDED "}
                >
                  <Button variant={"secondaryOutline"} onClick={onModelClose}>
                    Discard
                  </Button>
                  <Button
                    variant={"primary"}
                    color={colors.white}
                    type="submit"
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>

      {/* Update Immigration Status */}

      <Modal
        closeOnOverlayClick={false}
        isOpen={isUpdateStatusModalOpen}
        onClose={onUpdateModalClose}
        minWidth={"20%"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <Formik
            validationSchema={immigrationStateValidationSchema}
            initialValues={immigrationState}
            enableReinitialize={true}
            onSubmit={(values) => {
              let payload = {
                immigrationId: immigrationFileId,
                caseStatusId: values.caseStatusId,
                statusReason: values.statusReason,
              };
              api
                .put("ImmigrationFile/replace-file-status", payload)
                .then((res) => {
                  SuccessAlert("Updated sucessfully");
                  onUpdateModalClose();
                  getAllRecords();
                  setEnquiryFileId(null);
                })
                .catch((err) => {
                  console.log(err, "err");
                });
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form
                noValidate={true}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <ModalHeader borderBottom={"1px solid #EDEDED"}>
                  Update Status
                </ModalHeader>
                <ModalCloseButton
                  bg={colors.primaryColor}
                  color={colors.white}
                  position={"absolute"}
                  top={"15px"}
                  onClick={onUpdateModalClose}
                />
                <ModalBody padding={"20px"}>
                  <SimpleGrid
                    columns={{ base: "1", sm: "1", md: "1", lg: "1" }}
                    spacingX="-3px"
                    spacingY="-6px"
                    gap={"10px"}
                  >
                    <SelectInput
                      formControl={{
                        id: "caseStatusId",
                        variant: "floating",
                      }}
                      isRequired={true}
                      isReadOnly={isLoading}
                      value={values.caseStatusId}
                      label={{
                        text: "File Status",
                        colors: errors.caseStatusId ? "red !important" : "",
                        variant: errors.caseStatusId ? "errored" : "",
                      }}
                      name="caseStatusId"
                      onChange={(e) => {
                        if (e.target.value == CASE_STATUS.ACTIVE)
                          setStatusReason(true);
                        else setStatusReason(false);
                        handleChange(e);
                      }}
                      options={caseStatus}
                      variant={errors.caseStatusId ? "errored" : "flushed"}
                    />
                    {isStatusReason && (
                      <TextArea
                        name="statusReason"
                        value={values.statusReason}
                        placeholder={""}
                        isRequired={true}
                        resize="auto"
                        rows={20}
                        w={"100%"}
                        height="unset"
                        minH={"300px!important"}
                        label={{
                          text: "Reason",
                          colors: errors.statusReason ? "red !important" : "",
                          variant: errors.statusReason ? "errored" : "",
                        }}
                        onChange={handleChange}
                        variant={errors.statusReason ? "errored" : "flushed"}
                      />
                    )}
                  </SimpleGrid>
                </ModalBody>
                <ModalFooter
                  justifyContent={"space-between"}
                  borderTop={"1px solid #EDEDED "}
                >
                  <Button
                    variant={"secondaryOutline"}
                    onClick={onUpdateModalClose}
                  >
                    Discard
                  </Button>
                  <Button
                    variant={"primary"}
                    color={colors.white}
                    type="submit"
                    isDisabled={isStatusReason && !values.statusReason}
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>

      {/*Immigration Create Status */}

      <Modal
        closeOnOverlayClick={false}
        isOpen={isDocumentOpen}
        onClose={onDocumentClose}
        size={"6xl"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          w="100% !important"
          minW={{
            base: "100% !important",
            md: "900px !important",
            lg: "1000px !important",
          }}
        >
          <Formik
            validationSchema={manageDocumentValidationSchema}
            initialValues={manageDocument}
            enableReinitialize={true}
            onSubmit={(values) => {
              const setStatusIds = pendingDocuments
                .filter((item) => fileDocuments.includes(item.fileDocumentId))
                .map((item) => ({
                  fileDocumentId: item.fileDocumentId,
                  previousDocumentDate: item.previousDocumentDate,
                }));
              const removeStatusIDs = pendingDocuments
                .filter((item) => !fileDocuments.includes(item.fileDocumentId))
                .map((item) => ({
                  fileDocumentId: item.fileDocumentId,
                  previousDocumentDate: item.previousDocumentDate,
                }));

              let payload = {
                immigrationFileId: immigrationFileId,
                setStatusIds: setStatusIds,
                removeStatusIds: removeStatusIDs,
                userName: values.userName,
              };
              api
                .post("/ImmigrationFile/create-document-status", payload)
                .then((res) => {
                  SuccessAlert("Updated documents sucessfully");
                  onDocumentClose();
                  getAllRecords();
                  setEnquiryFileId(null);
                  setActiveCheckbox([]);
                })
                .catch((err) => {
                  console.log(err, "err");
                });
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form
                noValidate={true}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <ModalHeader borderBottom={"1px solid #EDEDED"}>
                  <Box display={"flex"} gap={10} fontSize={"16px"}>
                    <Box>
                      <label>Applicant: </label>
                      <label>{singleFileDetail?.name}</label>
                    </Box>
                    <Box>
                      <label>File Number: </label>
                      <label>{singleFileDetail?.fileNumber}</label>
                    </Box>
                  </Box>
                </ModalHeader>
                <ModalCloseButton
                  bg={colors.primaryColor}
                  color={colors.white}
                  position={"absolute"}
                  top={"15px"}
                  onClick={onDocumentClose}
                />
                <ModalBody>
                  <Box sx={{ columnCount: [1, 2, 3] }}>
                    {pendingDocuments?.map((doc, index) => (
                      <Box key={index} fontSize={"15px!important"}>
                        <Checkbox
                          fontSize={"15px"}
                          fontWeight={"light !important"}
                          id={"check" + index}
                          isDisabled={
                            ROLES.BRANCH_HEAD == selector.auth.login.RoleId &&
                            ADMIN_ALLOWED_DOCUMENT.findIndex(
                              (x) => x.id == doc.fileDocumentId
                            ) > -1
                          }
                          isChecked={
                            fileDocuments.includes(doc.fileDocumentId)
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            pendingDocuments[index].isActive = e.target.checked;
                            setPendingDocuments([...pendingDocuments]);
                            if (fileDocuments.includes(doc.fileDocumentId)) {
                              const fileIndex = fileDocuments.findIndex((x) =>
                                x.includes(doc.fileDocumentId)
                              );
                              fileDocuments.splice(fileIndex, 1);
                              setFileDocuments([...fileDocuments]);
                            } else {
                              setFileDocuments((old) => [
                                ...old,
                                doc.fileDocumentId,
                              ]);
                            }
                            if (e.target.checked) {
                              setActiveCheckbox([...activeCheckbox, index]);
                            }
                            if (!e.target.checked) {
                              const deleteIndex = activeCheckbox.indexOf(index);
                              activeCheckbox.splice(deleteIndex, 1);
                              setActiveCheckbox([...activeCheckbox]);
                            }
                          }}
                        >
                          {doc.name}
                          {getNameAndDate(doc.fileDocumentId)?.[0]
                            ?.previousDocumentDate && (
                              <Badge
                                fontWeight={"light !important"}
                                ml="1"
                                color={colors.black}
                                bg={colors.gray.gray200}
                              >
                                PD:{" "}
                                {convertDateFormat(
                                  getNameAndDate(doc.fileDocumentId)?.[0]
                                    ?.previousDocumentDate,
                                  "yyyy-mm-dd"
                                )}
                              </Badge>
                            )}
                          {getNameAndDate(doc.fileDocumentId)?.[0]
                            ?.createdOn && (
                              <Badge
                                fontWeight={"light !important"}
                                ml="1"
                                color={colors.black}
                                bg={colors.gray.gray200}
                              >
                                {convertDateFormat(
                                  getNameAndDate(doc.fileDocumentId)?.[0]
                                    ?.createdOn,
                                  "yyyy-mm-dd"
                                )}
                              </Badge>
                            )}
                          {getNameAndDate(doc.fileDocumentId)?.[0]
                            ?.userName && (
                              <Badge
                                ml="1"
                                bg={"transparent"}
                                borderColor={colors.blue.blue300}
                                color={colors.blue.blue300}
                                borderRadius={"30px"}
                                padding={"0px 10px"}
                                fontWeight={300}
                                border={"1px solid"}
                              >
                                {
                                  getNameAndDate(doc.fileDocumentId)?.[0]
                                    ?.userName
                                }
                              </Badge>
                            )}
                        </Checkbox>
                        {activeCheckbox.includes(index) &&
                          !getNameAndDate(doc.fileDocumentId)?.[0]
                            ?.previousDocumentDate && (
                            <TextInput
                              type="date"
                              name="previousDocumentDate"
                              maxVal={maxToDate}
                              value={
                                pendingDocuments[index].previousDocumentDate
                              }
                              onChange={(e) => {
                                const { value } = e.target;
                                pendingDocuments[index].previousDocumentDate =
                                  value;
                                setPendingDocuments([...pendingDocuments]);
                              }}
                              holder={""}
                              variant="flushed"
                              label={{ text: "Previous Date(if any)" }}
                            />
                          )}
                      </Box>
                    ))}
                  </Box>
                </ModalBody>
                <ModalFooter
                  justifyContent={"space-between"}
                  borderTop={"1px solid #EDEDED "}
                >
                  <Button
                    variant={"secondaryOutline"}
                    onClick={onDocumentClose}
                  >
                    Discard
                  </Button>
                  {activeCheckbox.length > 0 && (
                    <TextInput
                      type="text"
                      name="userName"
                      isRequired="true"
                      value={values.userName}
                      variant={errors.userName ? "errored" : "flushed"}
                      onChange={(e) => handleChange(e)}
                      placeholder={""}
                      label={{
                        text: "Please enter your name",
                        colors: errors.userName ? "red !important" : "",
                        variant: errors.userName ? "errored" : "",
                      }}
                      box={{ marginBottom: 0 }}
                    />
                  )}

                  <Button
                    variant={"primary"}
                    color={colors.white}
                    type="submit"
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Immigration;
