import { GET_PAGINATION, PAGE_SIZE, SET_PAGINATION } from "../../utils/constants";

const initialState = {
  pagination: {
    pageIndex: 1,
    pageSize: PAGE_SIZE,
    tabIndex:0
  }

};
const PaginationReducer = (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case SET_PAGINATION: {
      return {
        ...state,
        pagination: payload,
      };
    }
    case GET_PAGINATION: {
      return {
        ...state,
        pagination: state.pagination,
      };
    }
    default: {
      return state;
    }
  }
};

export default PaginationReducer;
