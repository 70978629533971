import { FILTER_ENUMS } from "../../core/utils/constants";

const FILTER_KEYS = [
  {
    key: "completeStageId",
    propertyName: "ImmigrationFileDocumentStatus.FileDocumentId",
    operator: FILTER_ENUMS.Equals,
    isList: true
  },
  {
    key: "pendingStageId",
    propertyName: "ImmigrationFileDocumentStatus.FileDocumentId",
    operator: FILTER_ENUMS.NotEqual,
    isList: true
  },
];

const CREATE = (data, default_Data) => {
  const payload = {
    caseStatusId: data.caseStatusId,
    enquiryId: data.enquiryId,
    branchId: data.branchId,
    counsellorId: data.counsellorId,
    name: data.applicantName,
    dob: data.dob,
    emailId: data.emailId,
    passportNumber: data.passportNo,
    phoneNumber: data.phoneNumber,
    alternateNumber: data.alternateNo,
    guardianName: data.father,
    address: data.address,
    stateId: data.stateId,
    districtId: data.districtId,
    fileNumber: default_Data.fileNumber,
    fileModeId: data.fileModeId,
    ieltsScore: data.ieltsScore,
    ieltsScoreValidDate: data.scoreValidUpTo,
    listeningScore: data.listeningScore,
    readingScore: data.readingScore,
    writingScore: data.writingScore,
    speakingScore: data.speakingScore,
    duration: data.duration,
    intakeId: data.intakeId,
    qualStreamId: data.qualStreamId,
    boardId: data.boardId,
    tenthPercentage: data.tenthPercentage,
    twelfthPercentage: data.twelfthPercentage,
    streamPercentage: data.streamPercentage,
    best3Details: data.best3Details,
    fundTypeId: data.fundTypeId,
    fileTypeId: data.fileTypeId,
    visaCollegeId: data.visaCollegeId,
    visaCollegeName: data.visaCollegeName,
    visaCourseId: data.visaCourseId,
    visaCourseName: data.visaCourseName,
    visaCountryId: data.visaCountryId,
    visaCityId: data.visaCityId,
    visaTypeId: data.visaTypeId,
    referenceId: data.referenceId,
    grade: data.grade,
    oldGrade:
      data.grade != default_Data.finalGrade
        ? default_Data.finalGrade
        : default_Data.oldGrade,
    finalGrade:
      data.grade != default_Data.finalGrade
        ? data.grade
        : default_Data.finalGrade,
    passportDoc: data.passportDoc,
    tenthDoc: data.tenthDoc,
    twelfthDoc: data.twelfthDoc,
    graduationDoc: data.graduationDoc,
    degreeDoc: data.degreeDoc,
    ieltsPTEDoc: data.ieltsPTEDoc,
    specialRemark: data.specialRemark,
    fileConversionDate: default_Data.fileConversionDate,
    filingOfficer: data.filingOfficer,
  };

  return payload;
};
export const IMMIGRATION_PAYLOAD = { CREATE, FILTER_KEYS };
