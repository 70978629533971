import { Navigate } from "react-router-dom";
import api from "../../api";
import {
  BRANCHES,
  BRANCH_ID,
  BRANCH_NAME,
  GET_BRANCH_ID,
  GET_PAGINATION,
  GET_LOGIN_USER_DATA,
  LAYOUT_TOGGLE,
  MENU_NAMES,
  OTP_REDUCER_DATA,
  PAGE_SIZE,
  PENDING_DOCUMENTS,
  REBIND_BRANCHES,
  REQUIRED_RESPONSE,
  ROUTE_PERMISSIONS,
  SET_PAGINATION,
  SIGN_IN,
  SIGN_OUT,
  USER_AUTH_TOKEN_KEY,
} from "../../utils/constants";
import { ROUTE_LINKS } from "../../utils/route-links";
import { PARSE_JWT } from "../../utils/common-function";
const token = JSON.parse(localStorage.getItem(USER_AUTH_TOKEN_KEY));

export const changeLayout = (className) => (dispatch) => {
  dispatch({
    type: LAYOUT_TOGGLE,
    payload: className,
  });
};

export const userSignIn =
  (decodeToken, navigate = false) =>
    (dispatch) => {
      dispatch({
        type: SIGN_IN,
        payload: decodeToken,
      });
      if (navigate) navigate(ROUTE_LINKS[MENU_NAMES.DASHBOARD]);
    };

export const userSignOut = () => (dispatch) => {
  const timeInterval = JSON.parse(localStorage.getItem("otpIntervalTime"));
  clearInterval(timeInterval);
  localStorage.clear();
  dispatch({
    type: SIGN_OUT,
    payload: true,
  });


};

export const getRequiredResponse = (responseObj) => (dispatch) => {
  dispatch({
    type: REQUIRED_RESPONSE,
    payload: responseObj,
  });
};

export const getLoggedInUserData = () => (dispatch) => {
  dispatch({
    type: GET_LOGIN_USER_DATA,
    payload: PARSE_JWT(token),
  });
};

export const setBranchId = (branchId) => (dispatch) => {
  dispatch({ type: BRANCH_ID, payload: branchId });
};

export const getBranchID = () => (dispatch) => {
  dispatch({ type: GET_BRANCH_ID });
};

export const setPendingDocuments = (response) => dispatch => {
  dispatch({
    type: PENDING_DOCUMENTS,
    payload: response
  })
}

export const setRoutePermissions = (permission) => dispatch => {
  dispatch({
    type: ROUTE_PERMISSIONS,
    payload: permission
  })
}

export const setBranchName = (text) => (dispatch) => {
  dispatch({ type: BRANCH_NAME, payload: text });
};

export const setBranches = (branches) => (dispatch) => {
  dispatch({ type: BRANCHES, payload: branches });
};

export const rebindBranches = (value) => (dispatch) => {
  dispatch({ type: REBIND_BRANCHES, payload: value });
};

export const setOTPData = (data) => (dispatch) => {
  dispatch({
    type: OTP_REDUCER_DATA,
    payload: data
  });
};

export const setPagination = (page) => (dispatch) => {
  dispatch({ type: SET_PAGINATION, payload: page });
};

export const getPagination = () => (dispatch) => {
  dispatch({ type: GET_PAGINATION });
};