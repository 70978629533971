import { Form, Formik } from "formik";
import {
  FormControl,
  Box,
  Button,
  WrapItem,
  CircularProgress,
  Flex,
  Heading, Stack, Image
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import api from "../../core/api";
import { useState } from "react";
import TextInput from "../../theme/components/input/text-input";
import { REFRESH_TOKEN_KEY, USER_AUTH_TOKEN_KEY, ERROR_CODES, MENU_NAMES, USER_IP_ADDRESS } from "../../core/utils/constants";
import { useNavigate } from "react-router-dom";
import { getIpAddress, PARSE_JWT } from "../../core/utils/common-function";
import { WarningAlert } from "../../core/utils/common-sweet-alert";
import { userSignIn, userSignOut } from "../../core/store/actions";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import { colors } from "../../theme/styles/colors";
import { FaArrowLeft } from 'react-icons/fa';
import {
  HStack,
  PinInput,
  PinInputField,
  ChakraProvider
} from "@chakra-ui/react";
const OTPVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selector = useSelector((_) => _);
  if (!selector?.otpFile.otpData?.username) {
    dispatch(userSignOut());
    navigate(ROUTE_LINKS[MENU_NAMES.LANDING]);
  }
  const [initialState, setInitialState] = useState({
    otpNumber: ''
  });

  const goBack = () => {
    navigate(ROUTE_LINKS[MENU_NAMES.LANDING]);
  };
  const [isLoading, setIsLoading] = useState(false);
  const resendOtp = async () => {
    const ipAddress = (await getIpAddress());
    let finalPayload = {
      username: selector?.otpFile.otpData
        .username,
      ipAddress: ipAddress ? ipAddress : "",
    };
    api
      .put(`${process.env.REACT_APP_API_BASE_URL}OTPAuthentication/replace`, { ...finalPayload })
      .then((res) => {
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  return (
    <Formik
      initialValues={initialState}
      onSubmit={async (values) => {
        setIsLoading(true);
        const ipAddress = (await getIpAddress()).data?.ip;
        api
          .get(`${process.env.REACT_APP_API_BASE_URL}OTPAuthentication/checkValidOtp/?userName=${selector?.otpFile.otpData.username}&ipAddress=${ipAddress}&otp=${values.otpNumber}`)
          .then(async (res) => {
            if (res.data.statusCode === 200) {
              api
                .post(`${process.env.REACT_APP_API_BASE_URL}Account/login`, {
                  username: selector?.otpFile.otpData.username,
                  password: selector?.otpFile.otpData.password,
                  ipAddress: ipAddress,
                  forLogin: true,
                })
                .then((res) => {
                  setIsLoading(false);
                  const parsedToken = PARSE_JWT(res.data.data.accessToken);
                  if (parsedToken.Permission.length == 0) {
                    WarningAlert(
                      "You do not have the permission to continue.\n For further action, please contact the administrator!",
                      "Action Required"
                    );
                    return;
                  }
                  if (res.data.statusCode == ERROR_CODES.OK) {
                    localStorage.setItem(
                      USER_AUTH_TOKEN_KEY,
                      JSON.stringify(res.data.data.accessToken)
                    );
                    localStorage.setItem(
                      REFRESH_TOKEN_KEY,
                      JSON.stringify(res.data.data.refreshToken)
                    );
                    localStorage.setItem(
                      USER_IP_ADDRESS,
                      ipAddress
                    );
                  }
                  dispatch(userSignIn(parsedToken, navigate));
                })
            }
          })
          .catch((err) => {
            if (err.response && err.response.data.message)
              WarningAlert(err.response.data.message);
            else if (err.message)
              WarningAlert(err.message);
            setIsLoading(false);
          });
      }}
    >
      {({ handleSubmit, handleChange, values, errors }) => (

        <Form
          noValidate={true}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Flex dir="row">
            <Box
              display={{ base: "none", sm: "none", md: "block", lg: "block" }}
              padding={{ base: "30px", sm: "30px", md: "10px", lg: "30px" }}
              border={1}
              borderColor={"green"}
              backgroundColor={'#fafafb'}
              borderWidth={1}
              h={"100vh"}
              w={{ base: "100%", sm: "100%", md: "50%", lg: "50%" }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box className="login">
                <Box marginTop={"60px"}>
                  <img src="/images/otp.png" alt="OTP" />
                </Box>
              </Box>
            </Box>
            <Box
              border={1}
              padding={{ base: "30px", sm: "30px", md: "10px", lg: "30px" }}
              borderWidth={1}
              h={"100vh"}
              borderColor={"#F0AC00"}
              w={{ base: "100%", sm: "100%", md: "50%", lg: "50%" }}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box w={{ base: "100%", sm: "100%", md: "50%", lg: "50%" }}>
                <Box
                  position={"absolute"}
                  right={"0px"}
                  top={"10px"}
                  fontSize={"14px"}
                ></Box>
                <Flex
                  justifyContent={"center"}
                  w={"200px"}
                  margin={"0px auto 20px"}
                >
                  <Image src="./images/DF_LOGO.png" />
                </Flex>
                <Flex
                  justifyContent={"center"}

                  margin={"0px auto 20px"}
                >
                  <Heading variant={"h2"}>OTP Verification</Heading>
                </Flex>
                <Stack spacing={6} marginBottom={"30px"}>
                  <Heading variant={"h5"}>
                    Enter your One-time password
                  </Heading>
                </Stack>
                <FormControl gap="3" marginBottom={"30px"}>
                  <HStack display={"flex"}
                    justifyContent={"center"}>
                    <PinInput
                      placeholder=''
                      name="otpNumber"
                      value={values.otpNumber}
                      onChange={(e) => {
                        let obj = {
                          target: {
                            name: "otpNumber",
                            value: e
                          }
                        }
                        handleChange(obj)
                      }}
                    >
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                </FormControl>
                <Box>
                  <Box
                    fontSize={"13px"}
                    cursor={"pointer"}
                    display={"flex"}
                    justifyContent={"center"}
                    textDecoration={"none"}
                    _hover={{ textDecoration: "none" }}
                  >
                    Didn't recevie the OTP? <Box textDecoration={"underline"} onClick={resendOtp} color={colors.primaryColor} paddingLeft={"4px"}> Resend OTP</Box>
                  </Box>
                </Box>
                <Box>
                  <WrapItem>
                    <Button
                      onClick={goBack}
                      fontSize={"13px"}
                      cursor={"pointer"}
                      marginTop={"20px"}
                      color={colors.primaryColor}
                      justifyContent={"center"}
                      textDecoration={"none"}
                      _hover={{ textDecoration: "none" }}
                      leftIcon={<FaArrowLeft />}
                    >
                      Go Back</Button>

                    <Button
                      variant={"primary"}
                      width={"100%"}
                      marginTop={"20px"}
                      type="submit"
                    >
                      {isLoading ? (
                        <CircularProgress
                          isIndeterminate
                          size="24px"
                          color={colors.white}
                        />
                      ) : (
                        "Verify"
                      )}
                    </Button>
                  </WrapItem>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>

  );
}

export default OTPVerification

