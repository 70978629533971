import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { colors } from "../../theme/styles/colors";
import Pagination from "../../theme/components/pagination/pagination";
import { MENU_NAMES, PAGE_SIZE, ROLES } from "../../core/utils/constants";
import { GRID_COLUMNS } from "../../core/utils/grid-columns";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import api from "../../core/api";
import { CREATE_RESPONSE } from "../../core/utils/common-function";
import { useSelector } from "react-redux";
import Grid from "../../theme/components/grid/grid";

const BranchUsers = (props) => {
  const navigate = useNavigate();
  const selector = useSelector((_) => _);
  const params = useLocation();
  const [currentPage, setCurrentPage] = useState(props.apiPayload.pageIndex);
 const [currentPageSize, setCurrentPageSize] = useState(props.apiPayload.pageSize);
  const hasExport =
    selector.auth.login.RoleId == ROLES.SYSTEM_ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.BRANCH_HEAD;
  const hasDelete =
    selector.auth.login.RoleId == ROLES.SYSTEM_ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.ADMINISTRATOR;
  const [columns, setColumns] = useState(
    GRID_COLUMNS.USER.filter((x) => x.Active && x.Visibility)
  );
  const [totalCount, setTotalCount] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);

  const onChange = (page) => {
    setCurrentPage(page);
    props.setAPIPayload({
      ...props.apiPayload,
      pageIndex: page,
    });
  };
  props.apiPayload.pageIndex=currentPage;
  props.apiPayload.pageSize=currentPageSize;
  
  const getAllRecords = () => {
    props.apiPayload.pageSize = currentPageSize;
    props.apiPayload.pageIndex = currentPage;
    api
      .post("/User/getAll", props.apiPayload)
      .then((res) => {
        setTotalCount(res.data.data.total);
        setDataSource(res.data.data.data);
        setData(CREATE_RESPONSE(GRID_COLUMNS.USER, res.data.data.data));
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  useEffect(() => {
    setData(CREATE_RESPONSE(columns, dataSource));
  }, [columns]);

  useEffect(() => {
    setCurrentPageSize(selector.paginationData.pagination.pageSize);
    setCurrentPage(selector.paginationData.pagination.pageIndex);
  }, [props.tabIndex]);

  useEffect(() => {
    if (props.apiPayload.branchId != selector.branch.userBranchId) {
      setCurrentPageSize(PAGE_SIZE);
      setCurrentPage(1);
    }
    else if (props.tabIndex == 0 && props.apiPayload.branchId != "") 
      getAllRecords();
  }, [props.apiPayload, selector.branch.userBranchId,props.tabIndex,currentPage,currentPageSize]);

  return (
    <div>
      <Grid
        name={"branch_users"}
        columns={GRID_COLUMNS.USER}
        aColumns={columns}
        data={data}
        navigateUrl={ROUTE_LINKS[MENU_NAMES.GENERAL_USER]}
        currentPage={currentPage}
        currentPageSize={currentPageSize}
        tabIndex={props.tabIndex}
        permission={props.permission}
        hasDelete={hasDelete}
        hasExport={hasExport}
        setColumns={(e) => setColumns(e)}
        viewRoute={ROUTE_LINKS[MENU_NAMES.VIEW_USER]}
        editRoute={ROUTE_LINKS[MENU_NAMES.EDIT_USER]}
        setRecordId={(id, record) => {
          props.setUserId(id);
          if (record.isDelete) props.setIsDeleteOpen(true);
        }}
      />
      <Pagination
        className="pagination-bar"
        currentPage={props.apiPayload.pageIndex}
        totalCount={totalCount}
        pageSize={props.apiPayload.pageSize}
        onPageChange={(page) =>
        {
          setCurrentPage(page);
          props.setAPIPayload({
            ...props.apiPayload,
            pageIndex: page,
          })
        }
        }
        handleRowStateChange={(e) => {
          setCurrentPageSize(e.target.value);
          setCurrentPage(1);
          props.setAPIPayload({
            ...props.apiPayload,
           pageIndex: 1,
            pageSize: e.target.value,
          });
        }}
        activeBackgroundColor={colors.primaryColor}
        activeTextColor={colors.gray.gray100}
        onChange={onChange}
      />
    </div>
  );
};
export default BranchUsers;
