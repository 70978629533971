import React, { useEffect } from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import "./pagination.scss";
import { Box, Select, Stack, Text } from "@chakra-ui/react";
import { PAGE_SIZE } from "../../../core/utils/constants";

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
  activeBackgroundColor,
  activeTextColor,
  handleRowStateChange,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  /* 
   UN-COMMENT: TO HIDE THE PAGINATION 
   WHEN THE TOTAL COUNT IS MET ON PAGE CHANGE
  */
  // if (currentPage === 0 || paginationRange.length < 2) {
  //   return null;
  // }
  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <>
      {totalCount > 0 && (
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          className="pagination-wrapper"
        >
          <Box></Box>
          <Box display={"flex"} gap={"20px"} alignItems={"center"}>
            <Box display={"flex"} alignItems={"center"} gap={"20px"}>
              <Text fontSize={"11px"} fontWeight={"600"}>Items per page</Text>
              <Select
                placeholder=""
                variant={"flushed"}
                right={"0px"}
                display={"flex"}
                w={"60px"}
                border={"1px solid #dddddd"}
                onChange={handleRowStateChange}
                value={pageSize}
                defaultValue={PAGE_SIZE}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50" >50</option>
                <option value="100">100</option>
              </Select>
            </Box>

            <Box fontSize={"15px"} padding={"20px 0px"} fontWeight={"500"}>
            <Box height={"40px"} lineHeight={"40px"} fontSize={"11px"} fontWeight={"600"}>
              {currentPage == 1 ? "1" : pageSize * (currentPage - 1) + 1} - {pageSize * currentPage >= totalCount ? totalCount : pageSize * currentPage}  {pageSize >= totalCount ? "items" : ("of " + totalCount + " items")}
              </Box>
            </Box>

            <ul
              className={classnames("pagination-container", {
                [className]: className,
              })}
            >
              <li
                className={classnames("pagination-item", {
                  disabled: currentPage === 1,
                })}
                onClick={onPrevious}
              >
                <div className="arrow left" />
              </li>
              {paginationRange.map((pageNumber, index) => {
                if (pageNumber === DOTS) {
                  return <li className="pagination-item dots">&#8230;</li>;
                }
                return (
                  <li
                    key={index}
                    className={classnames("pagination-item", {
                      selected: pageNumber === currentPage,
                    })}
                    onClick={() => onPageChange(pageNumber)}
                    style={{
                      backgroundColor:
                        pageNumber === currentPage ? activeBackgroundColor : "",
                      color: pageNumber === currentPage ? activeTextColor : "",
                      fontSize: pageNumber === currentPage ? "15px" : "",
                    }}
                  >
                    {pageNumber}
                  </li>
                );
              })}
              <li
                className={classnames("pagination-item", {
                  disabled: currentPage === lastPage,
                })}
                onClick={onNext}
              >
                <div className="arrow right" />
              </li>
            </ul>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Pagination;
