import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeLayout,
  rebindBranches,
  setBranchId,
  userSignOut,
} from "../../../core/store/actions";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { colors } from "../../styles/colors";
import { MENU_NAMES, ROLES } from "../../../core/utils/constants";
import { ROUTE_LINKS } from "../../../core/utils/route-links";
import SelectInput from "../input/select-input";
import { getAll } from "../../../core/utils/common-function";

const TopBar = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const selector = useSelector((_) => _);
  const [branch, setBranch] = useState([]);
  const [branchName, setBranchName] = useState("");
  const [branchId, setBranchID] = useState();
  const [imagePath, setImagePath] = useState(selector.auth.login.ImagePath);
  const showBranches =
    selector.auth.login.RoleId == ROLES.SYSTEM_ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.FILLING_HEAD;
  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });

  const getAllRecords = () => {
    const Branch = getAll("Branch", payload);

    Promise.all([Branch])
      .then((res) => {
        const [branch] = res;
        const Branch = branch.data.data.map((data) => ({
          text: data.name,
          value: data.branchId,
        }));
        const branchId =
        selector.auth.login.BranchId != ""
          ? selector.auth.login.BranchId :
          selector.branch.userBranchId != ""
            ? selector.branch.userBranchId
            : branch.data.data[0].branchId;
      const currentBranch = Branch.find((x) => x.value === branchId);
        dispatch(setBranchId(branchId));
        setBranch(Branch);
        setBranchID(branchId);
        setBranchName(currentBranch.text);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  useEffect(() => {
    dispatch(
      changeLayout(
        show
          ? {
              sidebar: "sidebar hide",
              wrapper: "wrapper full-wrapper",
            }
          : {
              sidebar: "sidebar",
              wrapper: "wrapper",
            }
      )
    );
  }, [show]);

  useEffect(() => {
    if (imagePath != selector.auth.login.ImagePath) {
      setImagePath(selector.auth.login.ImagePath);
      return;
    }

    if (selector.auth.login.RoleId != undefined || selector.branch.isRebind) {
      getAllRecords();
      dispatch(rebindBranches(false));
    }
  }, [
    selector.auth.login.ImagePath,
    selector.auth.login.RoleId,
    selector.branch.isRebind,
  ]);

  return (
    <Flex
      className="top-bar"
      backgroundColor={"white"}
      zIndex={"1"}
      color={useColorModeValue("gray.600", "white")}
      borderBottom={0}
      borderStyle={"solid"}
      borderColor={useColorModeValue("gray.200", "gray.900")}
      align={"center"}
      position={"fixed"}
      top={0}
      left={0}
      width={"100%"}
    >
      <Flex flex={{ base: 1 }} justify={{ base: "start", md: "start" }}>
        <Box
          textAlign={useBreakpointValue({ base: "start", md: "" })}
          fontFamily={"heading"}
          color={useColorModeValue("gray.800", "white")}
          position={"relative"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"70px"}
        >
          <Button
            colorScheme="blue"
            // borderRight={"1px solid #e2e8f0"}
            borderRadius={"50px"}
            padding={"0px"}
            textAlign={"center"}
            height={"48px"}
            width={"48px"}
            marginLeft={"10px"}
            marginRight={"10px"}
            _hover={{
              background: "#b1b1b130",
            }}
            // color={colors.white}
            // backgroundColor={colors.primaryColor}
            onClick={() => {
              setShow(!show);
            }}
          >
            <HamburgerIcon />
          </Button>
          {/* <Image
            src="/images/HSLogo.png"
            height={"55px"}
            className="bigLogo"
            marginLeft={"20px"}
            cursor={"pointer"}
            marginTop={"0px"}
            fontSize={{ base: "10px", sm: "14px", md: "none", lg: "none" }}
          ></Image>  */}
          <Image
            src="/images/DF_LOGO.png"
            height={"50px"}
            // width={"150px"}
            marginLeft={"0px"}
            cursor={"pointer"}
            marginTop={"0px"}
            fontSize={{ base: "10px", sm: "14px", md: "none", lg: "none" }}
            display={{ base: "none", md: "block" }}
          ></Image>

          <Image
            src="../favicon.svg"
            height={"50px"}
            // width={"150px"}
            marginLeft={"13px"}
            cursor={"pointer"}
            marginTop={"0px"}
            fontSize={{ base: "10px", sm: "14px", md: "none", lg: "none" }}
            display={{ base: "block", md: "none" }}
          ></Image>
        </Box>
      </Flex>

      <Flex alignItems={"center"}>
        <Menu>
          <Box
            fontSize={"12px"}
            padding={{ base: "0px 0px", md: "0px 10px", lg: "0px 24px" }}
            height={"55px"}
            display={"flex"}
            alignItems={"center"}
            gap={"10px"}
          >
            {showBranches ? (
              <Box
                minW={{ base: "100px", md: "150px" }}
                className={"topbarSelectBox"}
              >
                <SelectInput
                  marginBottom={"0px"}
                  formControl={{
                    id: "Branch_Name",
                    variant: "floating",
                    borderColor: "#000000!important",
                  }}
                  borderBottom={"2px solid red"}
                  isRequired={false}
                  height={"48px"}
                  value={branchId}
                  name="Branch_Name"
                  onChange={(e) => {
                    const { value } = e.target;
                    dispatch(setBranchId(value));
                    setBranchID(value);
                  }}
                  label={{
                    text: "Branch",
                  }}
                  options={branch}
                />
              </Box>
            ) : (
              branchName != "" && (
                <Box
                  fontSize={{ base: "10px", md: "12px" }}
                  paddingInlineStart={"0px"}
                  maxW={"250px"}
                  padding={{ base: "7px 10px", md: "7px 15px" }}
                  borderRadius={"30px"}
                  fontWeight={"500"}
                  border={"0px solid"}
                  borderColor={colors.badge.green}
                  background={"#f0ac0030"}
                >
                  <strong>Branch: </strong>
                  {branchName}
                </Box>
              )
            )}
            <Box
              fontSize={"12px"}
              paddingInlineStart={"0px"}
              maxW={"350px"}
              padding={"7px 15px"}
              borderRadius={"30px"}
              fontWeight={"500"}
              border={"0px solid"}
              borderColor={colors.primaryColor}
              background={"#f0ac0030"}
              className="rolebox"
              display={{ base: "none", md: "block", lg: "block" }}
            >
              <strong>Role: </strong>
              {selector.auth.login.RoleName}
            </Box>
          </Box>
          <MenuButton
            as={Button}
            rounded={"full"}
            variant={"link"}
            cursor={"pointer"}
            minW={0}
            marginRight={{ base: "5px", md: "20px" }}
          >
            <Avatar
              size={"sm"}
              src={`https://api.digitalfiling.co.in/Uploads/${imagePath}`}
            />
          </MenuButton>

          <MenuList padding={0} fontSize={"13px"} borderRadius={"5px"}>
            <Box
              fontSize={"12px"}
              margin={"10px"}
              paddingInlineStart={"0px"}
              maxW={"350px"}
              padding={"7px 15px"}
              borderRadius={"30px"}
              fontWeight={"500"}
              border={"0px solid"}
              borderColor={colors.primaryColor}
              background={"#f0ac0030"}
              className="rolebox"
              display={{ base: "block", md: "none", lg: "none" }}
            >
              <strong>Role: </strong>
              {selector.auth.login.RoleName}
            </Box>
            <MenuItem
              onClick={() => {
                navigate(ROUTE_LINKS[MENU_NAMES.PROFILE]);
              }}
              padding={"15px"}
              background={"transparent"}
            >
              Profile
            </MenuItem>
            <MenuDivider margin={0} />

            <MenuItem
              onClick={() => {
                dispatch(userSignOut());
                navigate(ROUTE_LINKS[MENU_NAMES.LANDING]);
              }}
              padding={"15px"}
              background={"transparent"}
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default TopBar;
