import { Box, Button, Checkbox, Collapse, Container, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BreadcrumbBox from '../../theme/components/breadcrumb';
import { FILTER_ENUMS, MENU_NAMES, PAGE_SIZE, ROLES } from '../../core/utils/constants';
import { GET_BREADCRUMB_ROUTES } from '../../core/utils/navbar-items';
import { colors } from '../../theme/styles/colors';
import { GoFilter } from 'react-icons/go';
import SelectInput from '../../theme/components/input/select-input';
import {
  CREATE_RESPONSE,
  convertDateFormat,
  createFilterPayload,
  followUpColors,
  getAll,
  getById,
  getFilterCount,
  getUsersByRole,
  handleCheckboxFilterChange,
  handleFilterChange,
  handleResetState,
  handleActiveFilter,
  createFilterState,
} from '../../core/utils/common-function';
import { useSelector } from 'react-redux';
import TextInput from '../../theme/components/input/text-input';
import Pagination from '../../theme/components/pagination/pagination';
import Grid from '../../theme/components/grid/grid';
import { GRID_COLUMNS } from '../../core/utils/grid-columns';
import { ROUTE_LINKS } from '../../core/utils/route-links';
import api from '../../core/api';
import useTableHeightFix from '../../core/hooks/useTableHeightFix';
import { useLocation } from 'react-router-dom';

const FollowupPersonnel = (props) => {
  const location = useLocation();
  const selector = useSelector((_) => _);
  const currentRoute = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const moduleId = selector.auth.login.Permission?.find((x) => x.route == currentRoute)?.moduleId;
  const [topHeight, resizeElementHeight, windowHeight] = useTableHeightFix();
  const [show, setShow] = useState(false);
  const [initialState, setInitialState] = useState({
    name: '',
    isActive: null,
  });
  const [activeFilter, setActiveFilter] = useState(0);
  const [enquiryStatus, setEnquiryStatus] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isToDate, setIsToDate] = useState(true);
  const [counsellors, setCounsellors] = useState([]);
  const [isLastActiveFilter, setIsLastActiveFilter] = useState(0);
  const [filterState, setFilterState] = useState({
    name: '',
    followUpDate: null,
    phoneNumber: '',
    fromDate: '',
    toDate: '',
    enquiryNumber: '',
    address: '',
    enquiryStatusId: '',
    counsellorId: '',
    pendingfollowup: {
      value: convertDateFormat(new Date(), 'mm-dd-yyyy'),
      propertyName: 'FollowUpDate',
      operator: FILTER_ENUMS.LessThan,
      type: 'datetime',
      isGrouped: true,
      checked: true,
    },
    todayfollowup: {
      value: convertDateFormat(new Date(), 'mm-dd-yyyy'),
      propertyName: 'FollowUpDate',
      operator: FILTER_ENUMS.Equals,
      type: 'datetime',
      isGrouped: true,
      checked: true,
    },
    futurefollowup: {
      value: convertDateFormat(new Date(), 'mm-dd-yyyy'),
      propertyName: 'FollowUpDate',
      operator: FILTER_ENUMS.GreaterThan,
      type: 'datetime',
      isGrouped: true,
      checked: true,
    },
  });
  const [currentPage, setCurrentPage] = useState(selector.paginationData.pagination.pageIndex);
  const [currentPageSize, setCurrentPageSize] = useState(selector.paginationData.pagination.pageSize);
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState(GRID_COLUMNS.ENQUIRY.filter((x) => x.Active && x.Visibility));
  const [data, setData] = useState([]);
  const hasExport =
    selector.auth.login.RoleId == ROLES.SYSTEM_ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.BRANCH_HEAD ||
    selector.auth.login.RoleId == ROLES.FILLING_HEAD;
  const [enquiryId, setEnquiryId] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [minToDate, setMinToDate] = useState(convertDateFormat(new Date(), 'yyyy-mm-dd'));
  const [enquiryState, setEnquiryState] = useState({
    enquiryStatusId: '',
  });
  const [isEnquiryOpen, setEnquiryOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isRemarkOpen, setRemarkOpen] = useState(false);
  const [markFollowup, setmarkFollowup] = useState({
    enquiryId: null,
    remark: '',
    followUpDate: '',
  });
  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });
  const [isMarkFollowupOpen, setMarkFollowupOpen] = useState(false);
  const [apiPayload, setAPIPayload] = useState({
    filter: [
      {
        checked: true,
        value: convertDateFormat(new Date(), 'mm-dd-yyyy'),
        propertyName: 'FollowUpDate',
        operator: FILTER_ENUMS.LessThan,
        type: 'datetime',
        isGrouped: true,
      },
      {
        checked: true,
        value: convertDateFormat(new Date(), 'mm-dd-yyyy'),
        propertyName: 'FollowUpDate',
        operator: FILTER_ENUMS.Equals,
        type: 'datetime',
        isGrouped: true,
      },
      {
        checked: true,
        value: convertDateFormat(new Date(), 'mm-dd-yyyy'),
        propertyName: 'FollowUpDate',
        operator: FILTER_ENUMS.GreaterThan,
        type: 'datetime',
        isGrouped: true,
      },
    ],
    pageSize: currentPageSize,
    pageIndex: currentPage,
    branchId: selector.branch.userBranchId,
  });
  const handleToggle = () => {
    setShow(!show);
  };
  const resetFilterState = () => {
    setCurrentPageSize(PAGE_SIZE);
    setCurrentPage(1);
    setAPIPayload({
      ...apiPayload,
      filter: [],
      pageIndex: 1,
      pageSize: PAGE_SIZE,
    });
    handleActiveFilter('delete', moduleId)
    handleResetState(filterState, setFilterState);
    setActiveFilter(0);
  };


  const lastActiveFilter = async () => {
    let filledFilter = [];
    const lastFilter = await handleActiveFilter('get', moduleId);
    if (lastFilter) {
      filledFilter = JSON.parse(lastFilter.data.data);
      if (filledFilter) {
        setCurrentPage(1);
        setCurrentPageSize(PAGE_SIZE);
        createFilterState(filledFilter, setFilterState, setActiveFilter);
      }
    }
    setAPIPayload({
      ...apiPayload,
      filter: filledFilter ? filledFilter : [],
      pageSize: PAGE_SIZE,
      pageIndex: 1,
    });
    setIsLastActiveFilter(1);
  };


  const onChange = (page) => {
    setCurrentPage(page);
    setAPIPayload({
      ...apiPayload,
      pageIndex: page,
    });
  };

  const getAllRecords = () => {
    api
      .post('/Enquiry/getAllFollowup', apiPayload)
      .then((res) => {
        setTotalCount(res.data.data.total);
        setDataSource(res.data.data.data);
        setData(CREATE_RESPONSE(GRID_COLUMNS.DAILY_FOLLOWUP, res.data.data.data));
      })
      .catch((err) => { });
  };

  useEffect(() => {
    getRecord();
  }, [isEdit, isView]);

  useEffect(() => {
    if (selector.branch.userBranchId)
      getAllPromises();
  }, []);

  const getAllPromises = () => {
    const EnquiryStatus = getAll('EnquiryStatus', payload);
    const Counsellors = getUsersByRole(ROLES.COUNSELLOR, selector.branch.userBranchId);
    Promise.all([EnquiryStatus, Counsellors])
      .then((res) => {
        const [enquiryStatuses, branchCounsellors] = res;
        const EnquiryStatus = enquiryStatuses.data.data.map((enquiryStatus1) => ({
          text: enquiryStatus1.name,
          value: enquiryStatus1.enquiryStatusId,
        }));
        const counsellorResp = branchCounsellors.data.map((coun) => ({
          text: coun.name,
          value: coun.userId,
        }));
        setEnquiryStatus(EnquiryStatus);
        setCounsellors(counsellorResp);
      })
      .catch((err) => {
        console.log(err, 'error');
      });
  };

  useEffect(() => {
    setData(CREATE_RESPONSE(columns, dataSource));
  }, [columns]);

  useEffect(() => {
    document.title = 'Follow Up | Digital Filing';
    if (isLastActiveFilter == 0)
      lastActiveFilter();
    else if (apiPayload.branchId != '' && apiPayload.branchId == selector.branch.userBranchId) {
      getAllRecords();
    }
    else if (apiPayload.branchId != selector.branch.userBranchId) {
      setCurrentPage(1);
      setCurrentPageSize(PAGE_SIZE);
      setAPIPayload({
        ...apiPayload,
        pageIndex: 1,
        pageSize: PAGE_SIZE,
        branchId: selector.branch.userBranchId,
      });
    }
    else {
      setAPIPayload({
        ...apiPayload,
        branchId: selector.branch.userBranchId,
      });
    }
  }, [apiPayload, selector.branch.userBranchId]);

  const getRecord = async () => {
    try {
      if (enquiryId) {
        let record = (await getById('Enquiry', enquiryId)).data;
        setInitialState({
          isActive: record.isActive,
          name: record.name,
        });
      }
    } catch (error) { }
  };

  useEffect(() => {
    resizeElementHeight(document.getElementsByClassName('chakra-table__container')[0]);
  }, [windowHeight]);

  return (
    <Container maxW="container.xll" padding={'20px'} paddingBottom={'0px'}>
      <Box>
        <BreadcrumbBox route={GET_BREADCRUMB_ROUTES(MENU_NAMES.DAILY_FOLLOWUP, true)} />
      </Box>
      <Box color={colors.white} display={'flex'} justifyContent={'space-between'} alignItems={'center'} marginBottom={'20px'}>
        <Box>
          <Heading variant={'h1'}>{MENU_NAMES.DAILY_FOLLOWUP}</Heading>
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={'20px'}>
          <Button onClick={handleToggle} variant={'secondary'}>
            <GoFilter fontSize={'20px'} />
            <Text marginLeft={'5px'} className="btnText ">
              Filter
            </Text>
            <Box
              position={'absolute'}
              top={'-19px'}
              right={'-1'}
              backgroundColor={colors.primaryColor}
              color={colors.white}
              borderRadius={'16px'}
              padding={'0px'}
              height={'30px'}
              width={'30px'}
              lineHeight={'30px'}
            >
              {activeFilter}
            </Box>
          </Button>
        </Box>
      </Box>
      <Box backgroundColor={colors.white} boxShadow={'0px 0px 35px 0px #F5F5F5'} borderRadius={'10px'} marginBottom={'20px'} className="filter-wrapper">
        <Collapse in={show}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              let indexes = [];
              const payloadArr = createFilterPayload(filterState);
              const finalArr = payloadArr.map((obj, index) => {
                if (obj.propertyName === 'pendingfollowup') {
                  if (!obj.checked) indexes.push(index);
                  obj.propertyName = 'FollowUpDate';
                  obj.operator = FILTER_ENUMS.LessThan;
                  obj.value = convertDateFormat(new Date(), 'mm-dd-yyyy');
                  obj.isGrouped = true;
                }

                if (obj.propertyName === 'todayfollowup') {
                  if (!obj.checked) indexes.push(index);
                  obj.propertyName = 'FollowUpDate';
                  obj.operator = FILTER_ENUMS.Equals;
                  obj.value = convertDateFormat(new Date(), 'mm-dd-yyyy');
                  obj['isGrouped'] = true;
                }
                if (obj.propertyName === 'futurefollowup') {
                  if (!obj.checked) indexes.push(index);
                  obj.propertyName = 'FollowUpDate';
                  obj.operator = FILTER_ENUMS.GreaterThan;
                  obj.value = convertDateFormat(new Date(), 'mm-dd-yyyy');
                  obj.isGrouped = true;
                }

                return obj;
              });

              if (indexes.length) {
                for (var i = indexes.length - 1; i >= 0; i--) finalArr.splice(indexes[i], 1);
              }
              const filterSum = getFilterCount(finalArr);
              setActiveFilter(filterSum);
              setCurrentPageSize(PAGE_SIZE);
              setCurrentPage(1);
              setAPIPayload({
                ...apiPayload,
                filter: finalArr,
                pageSize: PAGE_SIZE,
                pageIndex: 1,
              });
              const jsonString = JSON.stringify(payloadArr);
              handleActiveFilter('post', '', { moduleId: moduleId, StateJson: jsonString })
            }}
          >
            <SimpleGrid columns={{ base: '1', sm: '1', md: '5', lg: '5' }} spacingX="40px" padding={'20px'} paddingBottom={'0px'}>
              <TextInput
                type="date"
                name="fromDate"
                value={filterState.fromDate?.value}
                onChange={(e) => {
                  let D = new Date(e.target.value).setDate(new Date(e.target.value).getDate() + 1);
                  setMinToDate(convertDateFormat(D, 'yyyy-mm-dd'));
                  handleFilterChange(e, filterState, setFilterState, 'From');
                  if (e.target.value != '') {
                    setIsToDate(false);
                  }
                }}
                placeholder={''}
                variant="flushed"
                label={{ text: 'From' }}
              />
              <TextInput
                type="date"
                isDisabled={isToDate}
                name="toDate"
                minVal={minToDate}
                defaultValue={minToDate}
                value={filterState.toDate?.value}
                onChange={(e) => {
                  handleFilterChange(e, filterState, setFilterState, 'To');
                }}
                placeholder={''}
                variant="flushed"
                label={{ text: 'To' }}
              />
              <TextInput
                type="text"
                placeholder={''}
                name="Name"
                value={filterState.Name?.value}
                onChange={(e) => handleFilterChange(e, filterState, setFilterState, 'Name')}
                marginTop="20px"
                formControl={{ id: 'name' }}
                label={{ text: 'Name' }}
              />
              <SelectInput
                formControl={{ id: 'enquiryStatusId', variant: 'floating' }}
                isRequired={false}
                isReadOnly={false}
                value={filterState.EnquiryStatusId?.value}
                name="EnquiryStatusId"
                onChange={(e) => handleFilterChange(e, filterState, setFilterState, 'Enquiry Status')}
                label={{
                  text: 'Enquiry Status',
                }}
                options={enquiryStatus}
                showAllOption={true}
              />
              <TextInput
                type="number"
                placeholder={''}
                name="EnquiryNumber"
                value={filterState.EnquiryNumber?.value}
                onChange={(e) => handleFilterChange(e, filterState, setFilterState, 'Enquiry Number')}
                marginTop="20px"
                formControl={{ id: 'enquiryNumber' }}
                label={{ text: 'Enquiry Number' }}
              />
              <TextInput
                type="text"
                placeholder={''}
                name="Address"
                value={filterState.Address?.value}
                onChange={(e) => handleFilterChange(e, filterState, setFilterState, 'Address')}
                marginTop="20px"
                formControl={{ id: 'address' }}
                label={{ text: 'Address' }}
              />
              <TextInput
                type="text"
                placeholder={''}
                name="phoneNumber"
                value={filterState.phoneNumber?.value}
                onChange={(e) => handleFilterChange(e, filterState, setFilterState, 'Phone Number')}
                marginTop="20px"
                formControl={{ id: 'phoneNumber' }}
                label={{ text: 'Phone Number' }}
              />
              <SelectInput
                formControl={{
                  id: 'counsellorId',
                  variant: 'floating',
                }}
                isRequired={false}
                isReadOnly={false}
                name="CounsellorId"
                value={filterState.CounsellorId?.value}
                label={{
                  text: 'Counsellor',
                }}
                options={counsellors}
                onChange={(e) => handleFilterChange(e, filterState, setFilterState, 'Counsellor')}
                showAllOption={true}
              />
              <Checkbox
                __css={{
                  '& .chakra-checkbox__control': {
                    background: '#e2e8f0',
                  },
                }}
                type="checkbox"
                iconSize="30px"
                size={'md'}
                name="pendingfollowup"
                display={'flex'}
                isChecked={filterState.pendingfollowup?.checked}
                colorScheme="yellow"
                alignItems={'center'}
                onChange={(e) => handleCheckboxFilterChange(e, filterState, setFilterState, "Pending followup")}
              >
                <Text fontSize={13}>Pending followup</Text>
              </Checkbox>
              <Checkbox
                __css={{
                  '& .chakra-checkbox__control': {
                    background: '#e2e8f0',
                  },
                }}
                iconSize="30px"
                size={'md'}
                type="checkbox"
                name="todayfollowup"
                isChecked={filterState.todayfollowup?.checked}
                display={'flex'}
                colorScheme="yellow"
                alignItems={'center'}
                onChange={(e) => handleCheckboxFilterChange(e, filterState, setFilterState, "Today followup")}
              >
                <Text fontSize={13}>Today followup</Text>
              </Checkbox>
              <Checkbox
                __css={{
                  '& .chakra-checkbox__control': {
                    background: '#e2e8f0',
                  },
                }}
                iconSize="30px"
                size={'md'}
                display={'flex'}
                type="checkbox"
                name="futurefollowup"
                isChecked={filterState.futurefollowup?.checked}
                colorScheme="yellow"
                alignItems={'center'}
                onChange={(e) => handleCheckboxFilterChange(e, filterState, setFilterState, "Future followup")}
              >
                <Text fontSize={13}>Future followup</Text>
              </Checkbox>
            </SimpleGrid>
            <Box display={'flex'} justifyContent={'end'} paddingRight={'20px'} paddingBottom={'20px'}>
              <Button
                fontSize={{
                  base: '12px',
                  sm: '13px',
                  md: '14px',
                  lg: '14px',
                }}
                variant={'primary'}
                type="submit"
              >
                Search
              </Button>
              <Button
                fontSize={{
                  base: '12px',
                  sm: '13px',
                  md: '14px',
                  lg: '14px',
                }}
                variant={'warning'}
                marginLeft={'10px'}
                isDisabled={activeFilter > 0 ? false : true}
                onClick={resetFilterState}
              >
                Reset
              </Button>
            </Box>
          </form>
        </Collapse>
      </Box>
      <Grid
        name={'enquiry'}
        columns={GRID_COLUMNS.DAILY_FOLLOWUP}
        aColumns={columns}
        data={data}
        navigateUrl={ROUTE_LINKS[MENU_NAMES.DAILY_FOLLOWUP]}
        currentPage={currentPage}
        currentPageSize={currentPageSize}
        permission={props}
        hasDelete={false}
        hasExport={hasExport}
        isPopupComponent={true}
        setColumns={(e) => setColumns(e)}
        viewRoute={ROUTE_LINKS[MENU_NAMES.EDIT_VIEW_ENQUIRY]}
        editRoute={false}
        isPopupNavComponent={true}
        hasEdit={false}
        modeTypes={followUpColors}
        setRecordId={(id, props) => {
          setEnquiryId(id);
          if (props.isDelete) setIsDeleteOpen(true);
          else if (props.updateStatus) {
            setEnquiryState({
              ...enquiryState,
              enquiryStatusId: props.enquiryStatusId,
            });
            setEnquiryOpen(true);
          } else if (props.specialRemarks) {
            setRemarkOpen(true);
          } else if (props.updateFollowup) {
            setMarkFollowupOpen(true);
          }
        }}
      />

      <Pagination
        className="pagination-bar"
        currentPage={apiPayload.pageIndex}
        totalCount={totalCount}
        pageSize={apiPayload.pageSize}
        onPageChange={(page) => {
          setCurrentPage(page);
          setAPIPayload({
            ...apiPayload,
            pageIndex: page,
          })
        }
        }
        handleRowStateChange={(e) => {
          setCurrentPageSize(e.target.value);
          setCurrentPage(1);
          setAPIPayload({
            ...apiPayload,
            pageIndex: 1,
            pageSize: e.target.value,
          });
        }}
        activeBackgroundColor={colors.primaryColor}
        activeTextColor={colors.gray.gray100}
        onChange={onChange}
      />
    </Container>
  );
};

export default FollowupPersonnel;
